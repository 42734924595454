.main{
    width: 100%;
    max-width: 1340px;
    height: 100%;
    padding-right: .75rem;
    padding-left: .75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    /* align-items: center; */
}
.footertop{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* margin: 0 auto; */
    /* margin: 5% auto; */
    padding: 0px 0px 40px;
    gap: 100px;
}
.footer{
    background-image: url('../../Assets/Backgrounds/Footer/footerbackground.webp');
    width: 100%;
    /* height: 500px; */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
    /* position: absolute;
    bottom: 0%;
    left: 0%; */
    padding: 180px 0px 0px 0px;
    position: relative;
  }
.logo{
    display: block;
    width: 30%;
    object-fit: contain;
    /* padding-bottom: 30px; */
}
.footerOptionrow{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5% auto 0;
}
.section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.section1{
    width: 80%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: center; */
    flex-wrap: wrap;
    gap: 10px;
}
.title{
    font-family: 'Textfontfamily';
    color: #FFFFFF;
}
.value{
    /* margin: 0.4rem 0; */
    cursor: pointer;
}
.value1{
    margin: 0.4rem 0 0;
}
.hr{
    border: 1px solid #FFFFFF;
    width: 100%;
    margin: auto 0.5rem 0.5rem;
}
.footerbottommain{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
}
.footerbottom{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:20px;
}
.footerbottomIcons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
}
.company{
    font-family: 'Textfontfamily';
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}
.companyName{
    color: #FFFFFF;
}
.leftSection{
    display: flex;
    justify-content: space-between;
    flex: 1;
}
.rightSection{
    display: flex;
    gap: 100px;
    flex: 1;
    justify-content: end;
}
.paymentsContainer{
    display: flex;
    gap: 8px;
    align-items: center;
}

.payment{
    width: 65px;
}
.titleRight{
    min-width: 105px;
    font-family: 'Textfontfamily';
    color: #FFFFFF;
}

/* Large phones (portrait and landscape) */


@media (max-width: 992px) {
    .main{
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footertop{
        font-size: 8px;
    }
    .footer{
        margin-top: 0;
        padding: 100px 0px 0px 0px;
    }
}

@media only screen and (min-width: 1800px) and (max-width: 2400px){
    .Footer{
      margin-top: -300px;
    }
  
  }

  @media only screen and (min-width: 1200px) and (max-width: 1400px){
    .Footer{
      padding: 0;
    }
  }


  @media (max-width:842px){
    .leftSection{
        flex-direction: column;
        gap: 10px;
    }
    .rightSection{
        flex-direction: column;
        gap: 20px;
        padding-top: 65px;
    }
    .section{
        gap: 5px;
    }
    .section1 {
        width: 100%;
    }
    .footertop{
        width: auto;
        gap: 0;
    }
    .payment{
        width: 13%;
        height: auto;
        object-fit: contain;
    }
    
  }

  @media (max-width:767px){
    .logo{
        display: none;
    }
    .rightSection{
        padding-top: 10px;
    }
  }
  @media (max-width:480px){
    .company{
        font-size: 10px;
    }
  }