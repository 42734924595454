/* VoucherBanner.css */
.voucher-banner {
    background-color: #8c5abe; /* Purple background */
    color: #fff;
    font-size: 18px; /* Slightly larger text size for readability */
    padding: 10px 20px;
    display: flex;
    align-items: center; /* Align icons and text vertically */
    justify-content: center; /* Center the text */
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .voucher-message {
    color: #fff;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    margin: 0 10px;
  }
  
  .vouchercd {
    font-weight: bold;
  }
  
  /* Icon styles */
  .icon {
    color: #fff;
    font-size: 20px; /* Size of the icons */
    margin: 0 15px; /* Space between icons and message */
  }
  
  /* Responsive design */
  @media (max-width: 1024px) {
    .voucher-banner {
      font-size: 16px; /* Slightly smaller text on tablets */
      padding: 8px 15px; /* Adjust padding for smaller screens */
    }
  
    .voucher-message {
      font-size: 16px; /* Adjust text size */
    }
  
    .icon {
      font-size: 18px; /* Smaller icon size */
      margin: 0 10px; /* Less margin between icons and text */
    }
  }
  
  @media (max-width: 768px) {
    .voucher-banner {
      font-size: 14px; /* Smaller text on mobile */
      padding: 6px 10px; /* Less padding on mobile */
    }
  
    .voucher-message {
      font-size: 14px; /* Adjust text size */
    }
  
    .icon {
      font-size: 16px; /* Even smaller icon size */
      margin: 0 8px; /* Less margin on mobile */
    }
  }
  
  @media (max-width: 480px) {
    .voucher-banner {
      font-size: 12px; /* Much smaller text on very small screens */
      padding: 5px 10px; /* Tighten padding for very small screens */
    }
  
    .voucher-message {
      font-size: 11px; /* Smaller text */
    }
  
    .icon {
      font-size: 14px; /* Smaller icon size */
      margin: 0 5px; /* Reduced margin on small screens */
    }
  }
  