.form-container {
    max-width: 420px;
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 40px;
    /* Ensure the container doesn't overflow */
    /* width: 100%; */
    box-sizing: border-box;
  }
  
  .form-container button {

    width: 100%;
    padding: 10px;
    margin-top: 25px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;

    cursor: pointer;
  }

  
    /* src/StyledButton.css */
.styledButton {
    background: linear-gradient(rgb(130, 193, 200), rgb(107, 156, 161));
    border: none;
    border-radius: 20px;
    color: white;
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 155px;
    height: 42px;
    cursor: pointer;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    transition: transform 0.2s;
  }
  
  .styledButton:hover {
    transform: scale(1.05);
  }
  
  .styledButton:active {
    transform: scale(0.95);
  }
  
  .form-container button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
  }
  
  .form-container .error {
    color: red;
    margin-top: 10px;
  }
  
  .form-container .success {
    color: green;
    margin-top: 10px;
  }

  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid white;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }

  .fields-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
}
.form-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.field-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.field-label {
    font-weight: bold;
    font-size: 14px;
    color: #555;
    text-transform: capitalize;
}

.field-value {
    font-size: 14px;
    color: #333;
}
.styled-button {
    width: 100%;
    padding: 12px;
    background-color: #8c5abe; /* Theme color */
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.styled-button:hover {
    background-color: #7a4ea4; /* Darker shade for hover */
}

  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .form-container {
      padding: 15px;
      margin-top: 20px;
    }
  
    .form-container button {
      padding: 8px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .form-container {
      padding: 10px;
      margin-top: 10px;
    }
  
    .form-container button {
      padding: 6px;
      font-size: 12px;
    }
  }
  