.profileTopRight {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 2px solid #BACD53;
    border-radius: 20px;
    padding: 30px;
    width: 650px;
}

.leftBtnsContainer {
    width: 192px;
}
.leftBtnsContainerSaveChanges {
    width: 192px;
    align-self: end;
}

.iconDivLock{
    display: flex;
}

.confirmPassword{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 75%;
    gap: 10px;
}

.leftBtns {

    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    border: 2px solid #BACD53;
    border-radius: 15px;
    padding: 12px 0;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    background-color: #BACD53;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detailsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profileRightContent {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    /* gap: 15px; */
    justify-content: space-between;
    /* width: 500px; */
    padding: 30px;
}

.rightTitle {
    font-family: 'CustomFont' !important;
  font-weight: normal;
  color: #BACD53;
    font-size: 30px;
}

.detailsColLeft {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.detailsColRight {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.detailsColRightInputs {
    display: flex;
    flex-direction: column;
    gap: 18px;
}


.label {
    color: #BACD53;
    font-size: 22px;
}

.value {
    color: #2D6734;
    font-weight: 700;
    font-size: 22px;
}

.rightPara {
    color: #2D6734;
    width: 458px;
    text-align: center;
    padding: 0 24px;
}

.profileTopRightBottom {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 2px solid #BACD53;
    border-radius: 20px;
    padding: 30px;
    width: 650px;
    align-items: center;
}

.rightTitleBottom {
    font-family: 'CustomFont' !important;
    font-weight: normal;
    color: #BACD53;
    font-size: 30px;
    width: 100%;
}

.rightParaBottom {
    color: #BACD53;
    /* width: 562px; */
    font-size: 12px;
    width: 80%;
    text-align: center;
    padding: 0 24px;
}

.accountDetailsContainer {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.customInput {
    background: transparent;
    border: none;
    border-bottom: 2px solid #2D6734;
    width: 321px;
    outline: none;
    color: #2D6734;
    font-weight: 600;
    font-size: 16px;
}
.loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid white;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.disabledInput {
    pointer-events: none;  
    opacity: 0.5;        
    background: transparent; 
    border: none;
    margin-top: 5px;
    border-bottom: none;
    width: 321px;
    outline: none;
    color: #2D6734;
    font-weight: 600;
    font-size: 16px;
}


.error{
    color: red;
    font-size: 13px;
}
@media  (max-width: 768px){
    /* .profileTopRight{
        width:80%;
        padding: 0 15px;
    }
} */

/* @media (max-width:425px){ */
    .accountDetailsContainer{
        align-items: center;
    }
    .leftBtnsContainer{
        width: 150px;
    }
    .leftBtns{
        font-size: 15px;
        margin: 0.5rem 0;
    }
    .detailsTop{
        padding: 10px;
    }
    .label{
        font-size: 12px;
    }
    .value{
        font-size: 12px;
    }
    .profileRightContent{
        padding: 10px;
    }
    .profileTopRight{
        width: 95%;
        padding: 0 15px;
    }
    .rightTitle{
        font-size: 14px;
    }
    .rightParaBottom{
        width: auto;
        padding: 0;
    }
    .profileTopRightBottom{
        width: 95%;
        padding: 15px;
    }
    .customInput{
        width: 95% !important;
    }
    .detailsColRight{
        gap: 15px;
    }
}

/* @media (max-width:375px){
    .profileTopRight{
        width: 342px;
    }
    .detailsTop{
        padding: 7px;
    }
    .rightTitle{
        font-size: 18px;
    }
    .leftBtnsContainer{
        width: 120px;
    }
    .leftBtns{
        font-size: 12px;
    }
    .label{
        font-size: 12px;
    }
    .value{
        font-size: 12px;
    }
    .profileTopRightBottom{
        width: 342px;
    }
    .rightTitleBottom{
        font-size: 22px;
        text-align: center;
    }
    .profileTopRightBottom .leftBtnsContainer{
        width: 180px !important;
    }

    .profileTopRightBottom .leftBtns{
        padding: 8px 0px;
    }
    .customInput{
        width: 190px !important;
    }
    .detailsColRightInputs{
        gap: 10px;
    }
    .profileRightContent .detailsColRight{
        gap: 15px;
    }

}


@media (max-width:320px){
    .profileTopRight{
        width: 275px;
    }
    .detailsTop{
        padding: 5px;
    }
    .rightTitle{
        font-size: 16px;
    }
    .leftBtnsContainer{
        width: 100px;
    }
    .profileRightContent {
        padding: 5px 0;
        gap: 10px;
    }
    .leftBtns{
        font-size: 12px;
    }
    .label{
        font-size: 10px;
    }
    .value{
        font-size: 10px;
    }
    .profileTopRightBottom{
        width: 275px;
    }
    .customInput{
        width: 170px !important;
    }
    .detailsColRightInputs{
        gap: 8px !important;
    }
    .profileRightContent .detailsColRight{
        gap: 15px;
    }


} */