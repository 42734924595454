.AboutusComponent {
    width: 100%;
    min-height: 50vh;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    padding: 2rem;
    text-align: center;
    position: relative;
    margin-bottom: 25%;
}

.heading {
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    color: #452565;
    margin-top: 5%;
}

.paragraph {
    font-family: 'Textfontfamily';
    color: #000000;
    width: 35%;
    text-align: center;
    margin: 2% auto;
    /* font-weight: 600; */
    font-size: 1.2vmax;
}

/* Large phones (portrait and landscape) */
@media (min-width: 481px) and (max-width: 768px) {
    .heading {
        font-family: 'CustomFont';
        font-size: 5vw;
        font-weight: 400;
        line-height: 1.2;
        color: #452565;
        margin-top: 5%;
    }

    .paragraph {
        font-family: 'Textfontfamily';
        color: #000000;
        width: 60%;
        text-align: center;
        margin: 2% auto;
        font-size: 2vw;
    }
}

/* Tablets (portrait and landscape) */
@media (min-width: 769px) and (max-width: 1024px) {
    .paragraph {
        font-family: 'Textfontfamily';
        color: #000000;
        width: 60%;
        text-align: center;
        margin: 2% auto;
        font-size: 2vw;
    }
}

@media (max-width:768px){
    .paragraph{
      font-size: 12px;
      width: 80%;
      padding: 0 15px;
      text-align: center;
    }
  }