.popupContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 2px solid;
    border-radius: 8px;
    padding: 16px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 1;
  }
  
  .popupOpen {
    transform: scale(1); /* Fully visible */
  }
  
  .popupClose {
    transform: scale(0); /* Shrink to invisible */
    opacity: 0;
  }
  
  .iconContainer {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .messageContent {
    flex-grow: 1;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #000;
  }
  
  .closeButton:hover {
    color: #555;
  }
  