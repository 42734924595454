.PrivacyPolicy {
    width: 100vw;
    height: 100%;
}

.header_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headinSubTitle {
    text-align: center;
    width: 398px;
}

.mainContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.iconandcontent{
    width: 100vw;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading {
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color: #CE2665;
    margin: 0 auto;
    padding: 15px;
    /* padding-top: 50px; */
}
.taglinecontainer{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2.5rem auto;
}
.tagline{
    
    font-family: 'CustomFont';
    font-size: 42px;
    margin: 0.5rem;
    margin: 0 auto;
    text-align: center;
}

.quote{
    font-family: 'Courier New', Courier, monospace;
    color: green;
}
.dot{
    font-family: 'Courier New', Courier, monospace;
}
.astronautImgContainer {
    position: absolute;
    top: -20%;
    left: 50%;
    transform: translate(50%, 0);
}

.owlImgContainer {
    position: absolute;
    top: 10%;
    left: 0;
}

.foxImgContainer {
    position: absolute;
    bottom: 0;
    left: 0%;
}

.ballImgContainer {
    position: absolute;
    bottom: 0;
    right: 0px;
}
.imgast {
    width: 50%;
    height: 100%;
}
.imgowl {
    width: 50%;
    height: 100%;
}
.imgball {
    width: 50%;
    height: 100%;
}
.imgfox {
    width: 50%;
    height: 100%;
}

.content {
    width: 50%;
    min-height: 400px;
    margin-top: 30px;
    position: relative;
}

.paragraph {
    font-family: 'Textfontfamily';
    font-size: 1.2vmax;
    text-align: center;
    margin: 2rem auto 0;
}

.uolist {
    list-style-position: inside;
    padding: 1rem;
    list-style-type: none;
}

.uolist li {
    font-family: 'Textfontfamily';
    font-size: 1.2vmax;
    text-align: justify;
    text-align-last: center;
    /* margin: 0.8rem auto 0; */
    font-weight: 700;
    line-height: 25px;
    /* margin: 0.8rem auto 0; */
    /* padding-left: -1rem; */
}

.subheading {
    font-family: 'CustomFont';
    text-align: center;
    line-height: 1.2;
    margin: 1rem 0 0;
    font-size: 2vmax;
    font-weight: normal;
}

.HomeContactus {
    background-image: url('../../Assets/Backgrounds/OurStory/ourStoryBg.webp');
    min-width: 100%;
    min-height: 125vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.Footer {
    background-image: url('../../Assets/Backgrounds/Footer/footerbackground.webp');
    width: 100vw;
    /* height: 40%; */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
    position: absolute;
    bottom: -5%;
    left: 0%;
}

.newourstorycontent{
    width: 90%;
    min-height: 70vh;
    padding: 0 15px;
    margin: 1rem auto;
    position: relative;
    z-index: +1111111;
}

.description{
    text-align: center;
    margin: 0 auto;
    font-size: 1.9vmax;
}
.boardimg{
    width: 100%;
    display: block;
}
.textcontainer{
    width: 50%;
    margin: -55% auto 0;
    text-align: center;
    /* width: 40%;
    margin: 0 auto;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%,-50%);
    border: 1px solid black; */
}
.newul li{
    /* padding: 16px;
    color: #fff;
    font-size: 14px; */
    color: #fff;
    font-size: 2px;
    text-align: center;
    /* line-height: 1.4; */
    /* text-align: center; */
}
.newtagline{
    /* width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center; */
}

/* @media (max-width:500px) {
.boardimg{
    display: none;
}
.newul li{
    color: black;
}
.textcontainer{
    margin-top: 0;
}
} */

/* @media (max-width:1024px) {
    .astronautImgContainer {
        position: absolute;
        top: -198px;
        right: -285px;
        width: 350px;
        height: 350px;
    }

    .owlImgContainer {
        width: 200px;
        position: absolute;
        top: 75px;
        left: -265px;
    }

    .ballImgContainer {
        position: absolute;
        top: 438px;
        right: -215px;
        width: 200px;
        height: 200px;
    }

    .foxImgContainer {
        z-index: 999;
        position: absolute;
        top: 695px;
        left: -305px;
        width: 150px;
    }

    .headinSubTitle {
        font-size: 12px;
        width: 277px;
    }
} */

/* @media (max-width:892px) {
    .astronautImgContainer {
        position: absolute;
        top: -165px;
        right: -215px;
        width: 250px;
        height: 250px;
    }

    .owlImgContainer {
        width: 150px;
        position: absolute;
        top: 115px;
        left: -200px;
    }

    .ballImgContainer {
        position: absolute;
        top: 585px;
        right: -160px;
        width: 150px;
        height: 150px;
    }

    .foxImgContainer {
        z-index: 999;
        position: absolute;
        top: 782px;
        left: -268px;
        width: 100px;
        display: none;
    }

    .headinSubTitle {
        font-size: 12px;
        width: 277px;
    }
} */

@media (max-width:767px) {
    .content{
        width: 90%;
    }
    /* ul li {
        font-size: 1.4vmax;
    } */
    .uolist li{
        font-family: 'Textfontfamily';
    font-size: 1.9vmax;
    text-align: center;
    text-align-last: center;
    /* margin: 0.8rem auto 0; */
    font-weight: 700;
    line-height: 25px;
    padding: 0 15px;
 /* margin: 0.8rem auto 0; */
    }
    .tagline{
        font-size: 26px;
    }
    .description{
        /* text-align: center;
        margin: 0 auto;
        font-size: 1.9vmax; */
    
    
        font-family: 'Textfontfamily';
        font-size: 1.9vmax;
        text-align: center;
        /* text-align-last: center; */
        /* margin: 0.8rem auto 0; */
        font-weight: 700;
        line-height: 25px;
        width: 90%;
        margin: 0 auto;
    }
    /* .astronautImgContainer {
        position: absolute;
        top: -165px;
        right: -215px;
        width: 250px;
        height: 250px;
        display: none;
    }

        
    

    

    .foxImgContainer {
        z-index: 999;
        position: absolute;
        top: 782px;
        left: -268px;
        width: 100px;
        display: none;
    } */

    .astronautImgContainer {
        position: absolute;
        top: 0;
        left: 61%;
        transform: translate(50%, 0);
    }
    
    .owlImgContainer {
        position: absolute;
        top: -6%;
        left: 0;
        transform: translate(-8%, 0);
    }
    
    .foxImgContainer {
        position: absolute;
        bottom: 4%;
        left: 0%;
        transform: translate(0, 0);
    }
    
    .ballImgContainer {
        position: absolute;
        bottom: 0%;
        right: -20%;
        transform: translate(20%, -50%);

    }
    .headinSubTitle {
        font-size: 12px;
        width: 277px;
    }
    .content {
        width: 80%;
    }
    .subheading{
        font-size: 22px;
    }
    /* ul li {
        font-size: 15px !important;
    } */
}

@media (max-width:426px){
    .content{
        width: 90%;
    }
    .subheading{
        font-size: 18px;
    }
    /* ul li {
        font-size: 12px !important;
    } */
    .heading{
        font-size: 22px;
    }
    .astronautImgContainer {
       display: none;
    }
    
    .owlImgContainer {
        display: none;
    }
    
    .foxImgContainer {
        display: none;
    }
    
    .ballImgContainer {
        display: none;
    }
}

@media (min-width:1440px){
    
    
    .astronautImgContainer {
        position: absolute;
        top: -20%;
        left: 71%;
        transform: translate(55%, 0);
    }
}