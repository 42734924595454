.main{
    display: flex;
    flex-direction: column;
    gap: 50px;
    /* justify-content: center; */
    align-items: center;
    margin: 0 auto;
    width: 100%;
  }
  /* src/components/OurBooks.module.css */
  .bookContainer {
    display: flex;
    gap: 70px;
    justify-content: center;
    /* align-items: center; */
    /* flex-wrap: wrap; */
    margin: 0 120px;
    width: 100%;
  }
  
  .mainheading{
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color: #2D6734;
    margin: 0 auto;
    padding: 15px;
    /* padding-top: 50px; */
  }
  .profileTopLeft {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* width: 85%; */

  }
  .profileTopRight {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 2px solid #BACD53;
    border-radius: 20px;
    padding: 30px;
    width: 85%;
  }
  .leftTitle{
    font-family: 'CustomFont';
    color: #2D6734;
    font-size: 60px;
    text-align: center;
  }

  .leftBtnsContainer {
    width: 280px;
  }
  .leftBtns {

    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    border: 2px solid #BACD53;
    border-radius: 15px;
    padding: 12px 0;
    color: #BACD53;
    font-size: 20px;
    cursor: pointer;
}
@media  (max-width: 992px){
  .bookContainer{
    margin: 0;
    padding: 0px 15px;
    gap: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

  
@media  (max-width: 768px){
  
  .leftBtns{
    border: 2px solid #BACD53;
    /* color: #ffffff; */
    font-weight: 800;
  }
}

@media (max-width:1024px){
  .leftTitle{
    font-size: 35px;
  }
  .leftBtnsContainer{
    width: 180px;
  }
  .leftBtns{
    font-size: 15px;
    padding: 10px 0;
  }
  .profileTopRight{
    width: 500px;
  }
  
}

@media (max-width:425px){
  .profileTopRight{
    padding: 30px 0;
  }
}