.ContactUs {
    width: 100vw;
    height: 100%;
}

.mainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading {
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color: #BACD63;
    margin: 0 auto ;
    padding: 15px;
}

.HomeContactus {
    /* background-image: url('../../../Assets/Backgrounds/ShippingAddress/shippingaddress.png');
    min-width: 100%;
    min-height: 125vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.error{
  font-size: 12px;
  color: red;
}
.errordate{
  font-size: 12px;
  color: red; 
  /* width: 100px; */
}
.HomeContactustop{
    /* width: 100%;
    height: 100vh; */
    width: 100%;
    max-width: 1340px;
    height: max-content;
    padding: 0 15px;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
}
.formData{
    flex-basis: 55%;
    /* min-height: 80vh; */
    /* border: 1px solid black; */

    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem; */
    margin: 2% auto 0;
    padding: 2% auto;
    /* width: 35%; */
    /* max-width: 600px; */
    /* margin: 2% auto; */
}

.row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
  }
  .rowcardicons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem auto;
    gap: 1rem;
    width: 100%;
  }
  .expiredate{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin: 2% auto;
  }
  .colum{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .field {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .field label {
    margin: 0.5rem 0;
    font-weight: bold;
    color: #452565;
  }
  .fieldexpire label {
    margin: 0.5rem 0;
    font-weight: bold;
    color: #452565;
    flex: 1;
  }
  .fieldexpire input{
    padding: 0.2rem;
    border: 2px solid #BABABA;
    outline: none;
    border-radius: 1rem;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    margin: 0.5rem auto;
  }
  .field input,
  .field textarea {
    padding: 0.5rem;
    border: 1px solid #d2d2d2;
    outline: none;
    border-radius: 10px;
    font-size: 1rem;
    width: 100%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    box-sizing: border-box;
  }
  .inputrow{
    width: 70%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  .field textarea {
    height: 100px;
  }
.slash{
    color: gray;
    margin: 0 0.2rem;
}
.slashafter{
  color: gray;
    margin: 0 0.2rem;
    padding-top: 0.4rem;
}
.PlaceOrderbtncontainer{
    width: 147px;
    height: 38px;
    /* margin-left: auto; */
    margin: 0.4rem auto;
    /* border: 1px solid black; */
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: normal;
    /* background-color: #9265C2; */
}
.PlaceOrderbtn{
    cursor: pointer;
}
.discountorderbtn{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  /* margin: 20px auto;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer; */
  /* border: 1px solid black; */
}
.buttondiv{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CreateYourOwnBook{
  width: fit-content;
  cursor: pointer;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 50px;
  transition: 0.5s;
}
.CreateYourOwnBook:hover{
transform: scale(1.1);
}
.paymentDetails{
    /* min-height: 80vh; */
    flex-basis: 27%;
    /* border: 1px solid orange; */
    padding: 2% auto;
    margin: 2% auto 0;
    display: flex;
    flex-direction: column;
}
.paymentDetailscolumn{
  display: flex;
  flex-direction: column;
}
.HomeContactusbottom{
    background-image: url('../../../Assets/Backgrounds/ShippingAddress/shippingaddress.webp');
    min-width: 100%;
    min-height: 125vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}
.Footer {
    background-image: url('../../../Assets/Backgrounds/Footer/footerbackground.webp');
    width: 100vw;
    /* height: 40%; */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
    position: absolute;
    bottom: -5%;
    left: 0%;
}



/* Small phones (portrait and landscape) */
@media (max-width: 480px) {
  .heading{
    padding-top: 50px;
    font-size: 12vw;
    text-align: center;
  }
  .HomeContactustop{
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
    /* gap: 10px; */
    margin-bottom: 300px;
  }

  /* .paymentDetails{
    padding: 17px;
  } */

  .rowcardicons{
    width: max-content;
    margin-bottom: 5px;
    margin: 0;
  }
  .formData{
    min-height: auto;
  }
  .HomeContactusbottom{
    min-width: 100%;
    min-height: 125vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  
}

/* Large phones (portrait and landscape) */
@media (min-width: 481px) and (max-width: 1024px) {
  .HomeContactustop{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 650px;
  }
  .formData{
    flex-basis: 1%;
    width: 70%;
    min-height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .heading{
    padding-top: 50px;
    font-size: 5vw;
    text-align: center;
  }
  .paymentDetails{
    width: 70%;
    min-height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .rowcardicons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    width: max-content;
  }
  .HomeContactusbottom{
    min-height: 70%;
    ;
  }
}

/* Tablets (portrait and landscape) */
/* @media (min-width: 769px) and (max-width: 1024px) {
  
} */

/* Desktops (large screens) */
@media (max-width: 392px) {
  .formData{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 17px !important;
  }
  .field label{
    font-size: 12px;
  }
  .paymentDetails{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .fieldexpire label {
    font-size: 12px !important;
  }
}