.voucherFormContainer {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    height: auto;
    max-width: 1340px;
    width: 100%;
    /* height: 100%; */
    /* min-height: 100vh; */
    /* background-color: #eef2f7; */
    /* padding: 10px; */
    /* overflow: hidden;
    overflow-y: auto; */
    position: relative;
  }
  .downloadLink{
    text-decoration: underline;
    color: blue;
    background-color: transparent;
    cursor: pointer;
  }
  .downloadLink:hover{
    text-decoration: underline;
    color: rgba(89, 0, 255, 0.64);
    background-color: transparent;
    cursor: pointer;
  }
  .downloadLink:active{
    text-decoration: underline;
    color: rgba(0, 0, 255, 0.377);
    background-color: transparent;
    cursor: pointer;
  }
  .downloadLink:visited{
    text-decoration: underline;
    color: rgba(0, 0, 255, 0.24);
    background-color: transparent;
    cursor: pointer;
  }
  .voucherFormBox {
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 550px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    position: relative;
  }
  
  .voucherFormBoxsecond {
    /* background-color: white;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 550px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    position: relative; */

    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 25px;
  }

  .voucherFormBox:hover {
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15);
  }
  
  .formTitle {
    font-size: 1.2rem;
    /* font-weight: bold; */
    color: #3a3a3a;
    padding: 0.5rem;
    /* margin: 10px 0; */
    /* margin-left: 3rem; */
  }
  .insameline{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem auto;
  }
  .insamelinesecond{
    display: flex;
    justify-content: space-between;
  }
  .voucherFormBox input {
    width: 95%;
    padding: 15px;
    margin: 0 0.5rem 20px;
    border: 1px solid #dde2e8;
    border-radius: 8px;
    font-size: 0.9rem;
    background-color: #f9fafc;
    transition: border-color 0.3s ease;
  }
  .isActivelabel{
    /* border: 1px solid red; */
    width: 100%;
    height: 40px;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    padding: 5px;
  }
  .loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.384);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .isActivelabel input{
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .voucherFormBox input:focus {
    border-color: #5c6bc0;
    outline: none;
  }
  .isActivetitle{
    width: fit-content;
    height: 25px;
  }
  
  .submitButton {
    width: 60%;
    max-width: 550px;
    background-color: #5c6bc0;
    color: white;
    padding: 14px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    margin: 0.5rem auto;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #3949ab;
  }
  
  .errorMessage {
    color: #e74c3c;
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  .successMessage {
    color: #2ecc71;
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  .cloumnValue{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .inputValue{
    font-size: 0.8rem;
    color: gray;
    margin: 0 0.5rem 0.2rem;
  }
  .optionforuploadmain{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    border: 2px dashed indigo;
    border-radius: 25px;
    padding: 35px 25px;
    margin: 1rem auto;
  }
  .optionforuploadmain h2{
    text-align: center;
  }
  .optionforuploadmainbtns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
  }
  .optionforuploadmainbtn{
    width: 45%;
    background-color: #5c6bc0;
    color: white;
    padding: 14px;
    border: none;
    border-radius: 25px;
    font-size: 14px;
    cursor: pointer;
    margin: 0.5rem auto;
    transition: background-color 0.3s ease;
  }
  
  .optionforuploadmainbtn:hover {
    background-color: #3949ab;
  }
.mainbulk{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  .description{
    font-size: 0.9rem;
    color: gray;
    margin: 0.5rem auto;
    text-align: center;
  }
  .backbtn{
    background-color: black;
    color: #fff;
    padding: 6px 15px;
    display: flex;
    align-items: start;
    justify-content: space-around;
    width: fit-content;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.5s;
  }
  .backbtn:hover{
    background-color: rgba(0, 0, 0, 0.642);
  }
  .singlemain{
    width: 100%;
  }
  .titleandback{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .voucherForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* flex-wrap: wrap; */
    padding: 25px;
  }
  .voucherFormBoxmultiple {
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 350px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    margin: 0.5rem;
    cursor: pointer;
  }
.voucherFormBoxmultiple:hover{
  transform: scale(1.05);
}
  .voucherFormbulk{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    padding: 25px;
  }
  .stepstext{
    font-size: 14px;
    color: gray;
  }
  .voucherFormbulk input{
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    padding: 25px;
    border-radius: 25px;
  }
  .field{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0.5rem 10px;
  }
  .field label{
    font-size: 0.9rem;
    color: gray;
  }
  .voucherFormBoxmultiple input{
    width: 60%;
    padding: 10px;
    border: 1px solid #dde2e8;
    border-radius: 8px;
    font-size: 0.8rem;
    background-color: #f9fafc;
    transition: border-color 0.3s ease;
  }
  .dropdown {
    width: 100%;
    padding: 10px;
    margin: 0 0.5rem 20px;
    border: 1px solid #dde2e8;
    border-radius: 8px;
    font-size: 0.9rem;
    background-color: #f9fafc;
    transition: border-color 0.3s ease;
  }
  
  .dropdown:focus {
    border-color: #5c6bc0;
    outline: none;
  }
  