/* src/fonts.css */
@font-face {
    font-family: 'CustomFont';
    src: url('./Assets/Fonts/JELLY\ FRUIT.ttf') format('truetype');
}

@font-face {
    font-family: 'Textfontfamily';
    src: url('./Assets/Fonts/Gilroy-Regular.ttf') format('truetype');
}

