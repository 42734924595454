.main{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}
.top{
    min-width: 100%;
    /* min-height: 150vh;  */
    height: auto;

    /* background-image: url('../../Assets/Backgrounds/CreateYourCharacter/chrbg.webp'); */
    background-image: url('../../Assets/Backgrounds/CreateYourCharacter/chrbg.webp');
    background-size: 100% 100%; 
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 998;
    margin-bottom: 1%;
    box-shadow: 0px 52px 35px 9px rgba(0,0,0,0.1) inset;
}
.heading{
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto;
    padding: 15px 0 0;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color:#452565;
}
.characterparagraph{
    width: 48%;
    /* font-size: 1.4vw; */
    font-family: 'Textfontfamily';
    color: #000000;
    text-align: center;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}
.charcontactusbtn{
    margin: 25px auto 0;
    width: fit-content;
    position: relative;
    z-index: 1;
    transition: 0.5s;
}
.charcontactusbtn:hover{
    transform: scale(1.1);
}
.charcontactusbtnmobile{
    margin: 25px auto;
    width: fit-content;
    position: relative;
    z-index: 1;
    transition: 0.5s;
}
.container{
    max-width: 1340px;
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin: 1% auto 0;
    
}
.characterOptionLeft{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin: 0 auto;
}
.charactermain {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: auto;
    padding: 16px;
    border: 2px solid #452565;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.character{
    /* min-height: 50vh; */
    height: 350px;
    width: 300px;
    padding: 1rem;
    position: relative;
    overflow: auto;
    padding: 15px;
    margin: 0 auto;
    z-index: -1;
}

.character img{
    display: block;
    /* width: 42%; */
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    z-index: -1;
}
.charactertoggle{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    /* overflow: hidden; */
}
.charactertogglecommon{
    padding: 0.5rem;
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    border: 3px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.charactertoggleboy{
    transition: 0.5s;
    background-color: #452565;
    color: #fff;
}
.charactertogglegirl{
    transition: 0.5s;
    background-color: #452565;
    color: #fff;
}
.characterOptionRigth{ 
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
 
.skin{
    width: 100%;
    padding: 1rem;
    height: max-content;
    border-radius: 16px;
    background: rgba(255,255,255,0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.25);
}
.eye{
    width: 100%;
    margin-top: 1rem;
    height: max-content;
    padding: 1rem;
    border-radius: 16px;
    background: rgba(255,255,255,0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.25);
}
.optionsdivinnercontainer{
    display: flex;
    flex-direction: column;
}
.optionheading{
    color: #452565;
    padding: 5px;
}
.commonskin{
    width: 40px;
    height: 40px;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 2px solid transparent;
    transition: 0.5s;
}
.commonskin:hover{
    border: 2px solid #452565;
}

.commoneyehair{
    width: 80px;
    height: 80px;
    /* padding: 0.5rem; */
    border-radius: 0.5rem;
    cursor: pointer;
    border: 2px solid transparent;
    transition: 0.5s;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
}
.commoneyehair:hover{
    border: 2px solid #fff;
    transform: scale(1.1);
}
.theimageeye{
    position: absolute;
    top: 5%;
    left: 0%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: block;
    transform: scale(1.9);
    object-fit: contain;
}
.theimageeyegirl{
    position: absolute;
    top: 30%;
    left: 0%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: block;
    transform: scale(3);
    object-fit: contain;
}
.theimagehair{
    position: absolute;
    top: 25%;
    left: 0%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: block;
    transform: scale(1.1);
    object-fit: contain;
}
 
.lightskin{
    background-color: #E7A795;
}
.middleskin{
    background-color: #CD6D4F;
}
.darkskin{
    background-color: #793C2E;
}
 
.Browneye{
    /* background-color: #99583A; */
    border: 2px solid #99583A;
}
.Blueeye{
    /* background-color: #367BE3; */
    border: 2px solid #367BE3;
}
.Greeneye{
    /* background-color: #32CE47; */
    border: 2px solid #32CE47;
}
.Hazeleye{
    /* background-color: #B59515; */
    border: 2px solid #B59515;
}

.Blackhair{
    border: 2px solid #0F0F0F;
    /* background-color: #0F0F0F; */
}
.Brownhair{
    border: 2px solid #7F4019;
    /* background-color: #7F4019; */
}
.Gingerhair{
    border: 2px solid #B02A13;
    /* background-color: #B02A13; */
}
.Blondehair{
    border: 2px solid #CE9D58;;
    /* background-color: #CE9D58; */
}

.characteroptionskin{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap:15px;
    padding: 5px 0px;
}
.inputContainer{
    margin: 0 auto;
    width: 95%;
    /* height: 20vh; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    padding: 8px;
}
.inputContainerItem{
    width: 100%;
    margin: 0.2rem auto;
}
.characteroptionsdivs{
    width: 55%;
    /* height: 100%; */
    height: auto;
    display: flex;
    flex-direction: column;
}
.hair{
    width: 100%;
    height: max-content;
    margin-top: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    background: rgba(255,255,255,0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.25);
}
.longshort{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
    color: #452565;
}
.long{
    width: 20%;
    background-color: #fff;
    border-radius: 1rem;
    padding: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 0.4rem;
    margin-bottom: 0.4rem;
}
.short{
    width: 20%;
    background-color: #fff;
    border-radius: 1rem;
    padding: 0.4rem;
    margin-bottom: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.childName{
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.childNameinput{
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid gray;
    outline: none;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.bottom::before{
    content: '';
    background-image: url('../../Assets/Backgrounds/CreateYourCharacter/createyourcharacter.webp');
    width: 100%;
    min-height: 150vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    bottom: 20px;
}
.bottom{
    position: relative;
}
.bottomcontent{
    /* position: relative; */
    z-index: 999;
    min-height: 100vh;
    margin-bottom: 30%;
}

.Footer{
    background-image: url('../../Assets/Backgrounds/Footer/footerbackground.webp');
    width: 100vw;
    /* height: 40%; */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
    position: absolute;
    bottom: -5%;
    left: 0%;
  }
  .contactusbtn{
    width: 200px;
    height: 50px;
    background-color: #B880F4;
    border-radius: 2rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .contactusbtninside{
    width: 95%;
    height: 80%;
    background-color:   #A774DD;
    border: 1px solid   #A774DD;
    border-radius: 2rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
 .extradiv{
    width: 100%;
    height: 100%;
 }
 
  
.cyccharacters{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cyccharacters img{
    width: 30%;
}

.logoutpopup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.579);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1330;
  }
.logoutpopupbox{
    max-width: 450px;
    /* height: 100px; */
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    position: relative;
}
.textparagraph{
    color: gray;
    font-size: 1rem;
    margin: 0.5rem auto;
  }
  .btns{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0.5rem auto;
    font-size: 0.9rem;
  }
  .text{
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  .cancel{
    width: 100%;
    padding: 6px 15px;
    /* background-color: gray; */
    border: 1px solid gray;
    border-radius: 10px;
    cursor: pointer;
    color: gray;
    margin: 0 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cancel:hover{
    background-color: rgb(73, 72, 72);
    color: #fff;
  }
  .logout{
    width: 100%;
    padding: 7px 15px;
    background-color: rgb(93, 93, 184);
    border-radius: 10px;
    cursor: pointer;
    color: #fff;
    margin: 0 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logout:hover{
    background-color: rgb(54, 54, 119);
  }
  
  .inthesameline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
  }

  .togglebuttonglass {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 25px;
    color: white;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .active {
    background-color: #6a3e99;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .notactive {
    background-color: rgba(128, 128, 128, 0.534);
    color: lightgray;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .toggle-button-glass:hover {
    background-color: #9d6ae3;
  }
  

@media (max-width: 1340px){
    .cyccharacters img{
        width: 45%;
    }
}

@media (max-width: 1024px){
    .top{
        min-height: auto;
    }
    .container{
        height: auto;
        display: flex;
        /* flex-direction: column; */
    }
    .charactermain{
        /* width: 75%; */
        /* height: 70vh; */
        padding: 10px;
        margin: 0 auto;
    }
    .character img{
        width: 56%;
    }
    .inputContainer{
        height: 26%;
    }
    .characteroptionsdivs{
        width: 90%;
        margin: 0 auto;
    }
    .characterOptionLeft{
        height: auto;
        padding-top: 20px;
    }
    .extradiv{
        height: auto;
    }
    .commoneyehair{
        width: 80px;
        height: 80px;
    }
    .characterparagraph{
        /* font-size: 3vw; */
        width: 95%;
    }
    .bottomcontent {
        /* position: relative; */
        z-index: 999;
        min-height: 100vh;
        margin-bottom: 65%;
    }
}

@media (max-width: 768px){
    .charactermain{
        width: 50%;
    }
    .charactermain{
        border: none;
    }
    .characteroptionskin{
        flex-direction: column;
    }
    .hair{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .skin{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .eye{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .optionsdivinnercontainer{
        justify-content: center;
    align-items: center;
    }
    .character img{
        width: 200px;
    }
    .characteroptionsdivs{
        width: 35%;
    }
    .toggleButton{
        padding: 0.5rem 1rem;
    font-size: 16px;
    border-radius: 1rem;
    border: none;
    margin: 0.5rem auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .optionsbtn{
    padding: 0.5rem 1rem;
    font-size: 16px;
    border-radius: 1rem;
    border: none;
    background-color: #452565;
    color: #fff;
    margin: 0.2rem auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .shadowbtncls{
    border-radius: 50%;
    padding: 0.3rem;
    border: none;
    display:flex;
    justify-content:center;
    align-items:center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}

@media (max-width: 666px){
    .character{
        width: 200px;
    }
    .character img{
        width: 70%
    }
}
@media (max-width: 488px){
    .cyccharacters img{
        width: 50%;
    }
    .character{
        /* min-height: 40vh; */
        /* min-height: auto; */
        width: 200px;
    }
    .inputContainer{
        width: 75vw;
    }
    .character img{
        width: 70%;
        top: 50%;
    }
    .commoneyehair{
        width: 65px;
        height: 65px;
    }
    .charactermain{
        box-shadow: none;
    }
    .bottom::before{
        content: '';
        background-image: url('../../Assets/Backgrounds/CreateYourCharacter/createyourcharacter.webp');
        width: 100%;
        min-height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        object-fit: cover;
        bottom: 1px
    }
}
 
@media (max-width: 375px){
    .character{
        width: 150px;
    }
    .character img{
        width: 70%;
    }
}


