.voucherFormContainer {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    height: auto;
    /* min-height: 100vh; */
    /* background-color: #eef2f7; */
    /* padding: 10px; */
    position: relative;
  }
  
  .voucherFormBox {
    background-color: white;
    padding: 10px 30px 30px;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 850px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
  }
  
  .voucherFormBox:hover {
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15);
  }
  
  .formTitle {
    font-size: 1.3rem;
    /* font-weight: bold; */
    color: #3a3a3a;
    /* margin: 10px 0; */
  }
  .insameline{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .voucherFormBox input {
    width: 95%;
    padding: 10px 15px;
    margin: 0 0.5rem 20px;
    border: 1px solid #dde2e8;
    border-radius: 8px;
    font-size: 0.9rem;
    background-color: #f9fafc;
    transition: border-color 0.3s ease;
  }
  .dropdown{
    width: 95%;
    padding: 10px 15px;
    margin: 0 0.5rem 5px;
    border: 1px solid #dde2e8;
    border-radius: 8px;
    font-size: 0.9rem;
    background-color: #f9fafc;
    transition: border-color 0.3s ease;
  }
  .isActivelabel{
    /* border: 1px solid red; */
    width: 100%;
    height: 40px;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    padding: 2px 5px;
  }
  .loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.384);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .isActivelabel input{
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .voucherFormBox input:focus {
    border-color: #5c6bc0;
    outline: none;
  }
  .isActivetitle{
    width: fit-content;
    height: 25px;
  }
  
  .submitButton {
    width: 100%;
    background-color: #5c6bc0;
    color: white;
    padding: 14px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    margin: 2rem auto 0.5rem;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #3949ab;
  }
  
  .errorMessage {
    color: #e74c3c;
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  .successMessage {
    color: #2ecc71;
    margin-bottom: 15px;
    font-size: 14px;
  }
  .insamelinetitleandclose{
    width: 100%;
    display: flex;
    /* align-items: ; */
    justify-content: space-between;
    margin: 0.5rem auto 1.5rem;
  }
  .closeicon{
    font-size: 1.4rem;
    font-weight: bold;
    color: gray;
    cursor: pointer;
    transition: 0.5s;
    align-self: flex-end;
    background-color: lightgray;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* padding: 15px; */
    position: relative;
  }
  .closeicon span{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .closeicon:hover{
    color: rgb(73, 72, 72);
  }

  .cloumnValue{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .inputValue{
    font-size: 0.8rem;
    color: gray;
    margin: 0 0.5rem 0.2rem;
  }