.OurBooksMain{
  width: 100vw;
  height: 100%;
}
.mainContainer{
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
 margin: 2% auto 0;
  /* align-items: center; */
  /* padding: 2%; */
}
.heading{
  font-family: 'CustomFont';
  font-size: 5vw;
  font-weight: 400;
  line-height: 1.2;
  display: block;
  white-space: normal;
  word-wrap: break-word;
  color:#47826D;
  /* margin: 2% auto; */
}

.OurBooks {
  padding: 15px 0 15%;
  background-image: url('../../Assets/Backgrounds/OurBooksMain/top.webp');
  background-size: cover;
  background-position: top;
  min-width: 100vw;
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  /* margin: 2% auto; */
  position: relative;
  /* z-index: 999; */
}

.HomeContactus {
  position: relative;
}
.HomeContactus::before {
  content: '';
  background-image: url('../../Assets/Backgrounds/OurBooksMain/ourbooksmainbg.webp');
  width: 100vw;
  height: 130vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* object-fit: cover; */
  position: absolute;
  bottom: 20px;
}

/* Large phones (portrait and landscape) */
@media (max-width: 1024px) {
  .HomeContactus {
      min-height: 55%;
    }
    .Footer{
      background-image: url('../../Assets/Backgrounds/Footer/footerbackground.webp');
      width: 100vw;
      /* height: 40%; */
      background-size: cover;
      background-repeat: no-repeat;
      /* background-position: center; */
      position: absolute;
      bottom: 0%;
      left: 0%;
    }
    .OurBooks {
      min-height: auto; /* Ensure this covers the entire viewport height */
      margin: 0 auto;
  }
  .OurBooks{
    padding: 15px 0 35%;
  }
 
}
@media (max-width: 786px) {
  .OurBooks{
    padding: 15px 0 100%;
  }
}