
.heading {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }
  .main{
    position: relative;
  }
  .orderList{
    width: 100%;
  height: auto;
  max-height: 80vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
  }
  .voucherListContainer {
    /* padding: 20px; */
    /* background-color: #f9f9f9; */
    /* min-height: 100vh; */
    height: auto;
    max-height: 70vh;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    overflow-y: auto;
  }
  
  .voucherTable {
    width: 100%;
    max-width: 1340px;
    border-collapse: collapse;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .voucherTable th,
  .voucherTable td {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 0.9rem;
    color: #555;
  }
  
  .voucherTable th {
    /* background: linear-gradient(135deg, #4CAF50 0%, #66bb6a 100%); */
    background-color: rgb(93, 93, 184);
    color: white;
    font-weight: bold;
  }
  
  .voucherTable tr:nth-child(even) {
    background-color: #f7f7f7;
  }
  
  .voucherTable tr:hover {
    background-color: #f1f1f1;
  }
  
  .editButton,
  .deleteButton {
    /* background-color: #4CAF50; */
    background-color: rgb(93, 93, 184);
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  }
  
  .deleteButton {
    background-color: #ff4d4d;
  }
  
  .editButton:hover{
    background-color: rgb(54, 54, 119);
  }
  .deleteButton:hover {
    background-color: #b60404;
  }
  .successclas{
    background-color: #9ddb3c61;
    padding: 6px 8px;
    border-radius: 10px;
  }
  .received{
    background-color: #ebee4261;
    padding: 6px 8px;
    border-radius: 10px;
  }
  .fulldetailbtn{
    padding: 5px 10px;
    border-radius: 10px;
    background-color: rgb(93, 93, 184);
    color: #fff;
    cursor: pointer;
  }
.titleh2{
  padding: 5px;
}
.refresh{
  /* background-color: gray; */
  /* color: #fff; */
  font-size: 18px;

  width: fit-content;
  /* padding: 6px; */
  cursor: pointer;
}
.togglebutton{
  background-color: gray;
  color: #fff;
  width: fit-content;
  padding: 10px;
  text-wrap: nowrap;
  border-radius: 15px;
  cursor: pointer;
}
  .fulldetailbtn:hover{
    background-color: rgb(54, 54, 119);
  }

  .filterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    max-width: 1340px;
  }
  .filter{
    width: 60%;
    display: flex;
    align-items: center;
  }
  
  .filterContainer input:focus {
    border-color: #5c6bc0;
    outline: none;
  }
  
  .searchInput {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    margin: 0 0.5rem;
    border: 1px solid #ccc;
  }
  .topcontrolls{
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 0;
  }
  .selectFilter {
    width: 35%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .pagination {
    margin: 1rem 0;
    padding-bottom: 10px;
  }
  
  .pageItem {
    padding: 8px 12px;
    border: none;
    background-color: rgb(93, 93, 184);
    color: white;
    border-radius: 4px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pageItem:hover {
    background-color: rgb(54, 54, 119);
  }
  
  .activePage {
    background-color: rgb(54, 54, 119);
  }
  
  .logoutpopup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.179);
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 99; */
  }
  .logoutpopupbox{
    min-width: 650px;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 20px;
    /* overflow: hidden;
    overflow-y: auto; */

    /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  width: 80%; */
  }
  .characterDetails {
    padding: 0.5rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px; /* Adjust as needed */
  }
  
  .characterDetails p {
    margin: 0;
  }
  
  .closeicon{
    font-size: 1.4rem;
    font-weight: bold;
    color: gray;
    cursor: pointer;
    transition: 0.5s;
    align-self: flex-end;
    background-color: lightgray;
    /* width: 30px;
    height: 30px; */
    border-radius: 50%;
    padding: 15px;
    position: relative;
  }
  .closeicon span{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .closeicon:hover{
    color: rgb(73, 72, 72);
  }
  .btns{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0.5rem auto;
  }
  .listmaindiv{
    position: relative;
  }
  
  .btns{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0.5rem auto;
  }
  .text{
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  .cancel{
    width: 100%;
    padding: 8px 15px;
    /* background-color: gray; */
    border: 1px solid gray;
    border-radius: 10px;
    cursor: pointer;
    color: gray;
    margin: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cancel:hover{
    background-color: rgb(73, 72, 72);
    color: #fff;
  }
  .logout{
    width: 100%;
    padding: 8px 15px;
    background-color: rgb(93, 93, 184);
    border-radius: 10px;
    cursor: pointer;
    color: #fff;
    margin: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logout:hover{
    background-color: rgb(54, 54, 119);
  }
  .textparagraph{
    color: gray;
    font-size: 0.9rem;
    margin: 0.5rem auto;
  }
/* OrderCard.module.css */
.card {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 70vw;
  /* height: 60vh; */
  margin: 16px;
  background-color: #fff;
  transition: transform 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  /* padding: 10px; */
}
.cardleft {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
}
.card:hover {
  /* transform: scale(1.02); */
}
.maincontent{
  display: flex;
  /* align-items: center; */
}

.image {
  width: 100px;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
}
.itemDetails {
  display: flex;
  justify-content: start;
  align-items: start;
  /* flex-direction: column; */
  margin-bottom: 20px;
}
.allitems{
  height: 60vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 20px;
}
.itemDetailsWrapper{
  border: 1px solid gray;
  padding: 15px;
  border-radius: 25px;
  margin: 0.8rem auto;
}
.sectionTitle {
  font-size: 1.5rem;
  /* margin-bottom: 10px; */
}
.content {
  width: 50%;
  padding: 20px;
}

h6 {
  font-size: 0.9rem;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.792);
}
p{
  font-size: 0.9rem;
  color: gray;
}
.lineaboveheading{
  background-color: lightgray;
  margin: 0.5rem auto;
}
.subheading{
  font-size: 0.8rem;
  font-weight: bold;
  color: gray;
}
.title{
  font-size: 0.9rem;
  font-weight: bold;
}
.bookdetials{
  margin-left: 0.5rem;
}

.commoninlinecls{
  display: flex;
  align-items: center;
}