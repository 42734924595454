.loginContainer {
  position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #c3cfe2 0%, #c3cfe2 100%);
  }
  
  .loginBox {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 320px;
    text-align: center;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .inputField {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
  }

  .inputField:focus {
    outline: none;
    border: 1px solid #5c6bc0;
  }
  
  .loginButton {
    background-color: rgb(93, 93, 184);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .loginButton:hover {
    background-color: rgb(54, 54, 119);
  }
  
  .errorMessage {
    color: red;
    margin-top: 10px;
  }
  
  .loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.384);
    display: flex;
    justify-content: center;
    align-items: center;
  }