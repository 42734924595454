.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 10% auto; */
  }

  /* src/components/OurBooks.module.css */
.bookContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 5% auto 0;
}
.subheading{
  font-family: 'Textfontfamily';
  font-size: 22px;
  font-weight: 400;
}
.mainheading{
  font-family: 'CustomFont';
  font-size: 5vw;
  font-weight: 400;
  line-height: 1.2;
  display: block;
  white-space: normal;
  word-wrap: break-word;
  padding-top: 50px;
  /* color: #FFFFFF; */
}

/* Small phones (portrait and landscape) */
@media (max-width: 480px) {
  
}

/* Large phones (portrait and landscape) */
@media (min-width: 481px) and (max-width: 768px) {
  .subheading{
    margin: 2% auto;
    font-size: 3vw;
  }
  .mainheading{
    margin: 2% auto;
  }
  
}


@media (max-width:380px){
  .subheading{
    font-size: 12px;
  }
  .mainheading{
    color: #79BDE4 !important;
  }
}

@media (max-width:480px){
  .subheading{
    font-size: 15px;
  }
  .mainheading{
    color: #79BDE4 !important;
  }
}