.ContactUs{
    width: 100vw;
    height: 100%;
    position: relative;
}
.mainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.RouteimgURL{
    width: 260px;
    border-radius: 12px;
    box-shadow: rgb(105 105 105 / 20%) 0px 4px 20px 6px;
    object-position: left;
}
.heading{
    font-family: 'CustomFont';
    font-size: 150px;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color:#0E7581;
    margin: 0 auto;
    /* padding-top: 50px; */
    padding: 15px;
}
.content{
    width: 40%;
    height: 100%;
    /* margin-top: -10%; */
}
.error{
  font-size: 12px;
  color: red;
}
.optioncls{
  padding: 0.5rem;
}
.paragraph{
    font-family: 'Textfontfamily';
    font-size: 14px;
    text-align: center;
    line-height: 1.7;
    text-align-last: center;
    margin: 0 auto;
}
.HomeContactus {
    background-image: url('../../Assets/Backgrounds/ComingSoon/ComingSoon.webp');
    min-width: 100%;
    min-height: 120vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  .Footer{
    background-image: url('../../Assets/Backgrounds/Footer/redfooter.webp');
    width: 100vw;
    /* height: 40%; */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
    position: absolute;
    bottom: -14%;
    left: 0%;
    z-index: +999;
  }
 
 
 
  .main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
  }
 
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 45%;
    /* max-width: 600px; */
    margin: 2% auto;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  
  .field {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .field label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #0E7581;
  }
  
  .field input,
  .field textarea {
    padding: 0.5rem;
    border: 2px solid #BABABA;
    outline: none;
    border-radius: 1rem;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
  }
 
  .selectContainer select{
    width: 100%;
    border: none;
    outline: none;
  }
 
.selectContainer{
    padding: 0.5rem;
    border: 2px solid #BABABA;
    outline: none;
    border-radius: 1rem;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    background: #ffffff;
  }
  
  .field textarea {
    height: 100px;
  }
  
  .sendButton {
    align-self: flex-end;
    width: fit-content;
    height: 51px;
    background-color: transparent;
    /* border: none; */
    cursor: pointer;
    /* margin-left: auto; */
    margin: 0.5rem auto;
    /* padding: 0.75rem 1.5rem; */
    /* background-color: #007BFF; */
    /* color: #fff; */
    /* border: none; */
    /* border-radius: 4px; */
    /* cursor: pointer; */
  }
  


.icon1{
  position: absolute;
  top: 10%;
  left: 8%;
}

.icon2{
  position: absolute;
  top: 6%;
  right: 0;
}

.icon3{
  position: absolute;
  top: 50%;
  left: 0;
}

.icon4{
  position: absolute;
  top: 39%;
  right: 15%;
}

.icon5{
  position: absolute;
  top: 111%;
  left: 1%;
}

.icon6{
  position: absolute;
  top: 185%;
  left: 10%;
}

.icon7{
  position: absolute;
  top: 140%;
  right: 5%;
}

.icon8{
  position: absolute;
  top: 175%;
  right: 0;
}
.titleimage{
  position: relative;
  display: block;
  width: 45%;
  object-fit: contain;
  z-index: +1111111;
}

@media (max-width: 480px) {
  .form{
    width: 85%;
  }
  .content{
    width: 85%;
    margin: 5px auto;
  }
  .content p{
    font-size: 12px;
  }
  .heading{
    font-size: 60px !important;
  }
  .paragraph{
    font-size: 12px;
  }
  .titleimage{
    position: relative;
    display: block;
    width: 85%;
    object-fit: contain;
    z-index: +1111111;
    margin: 1.5rem auto;
  }
}

/* Tablets (portrait and landscape) */
@media (max-width: 768px) {
  .content{
    padding-bottom: 50px;
  }
  .form{
    width: 100%;
    padding: 0 15px;
  }
  .heading{
    font-size: 100px;
    text-align: center;
  }
  .paragraph{
    font-size: 15px;
  }
  /* .HomeContactus{
    min-height: 100vh;
  } */
   .icon1{
    display: none;
   }
   .icon2{
    display: none;
   }
   .icon3{
    display: none;
   }
   .icon4{
    display: none;
   }
   .icon5{
    display: none;
   }
   .icon6{
    display: none;
   }
   .icon7{
    display: none;
   }
   .icon8{
    display: none;
   }
}

@media (max-width: 1340px) {
  .titleimage{
    position: relative;
    display: block;
    width: 65%;
    object-fit: contain;
    z-index: +1111111;
  }
  .content{
    /* width: 85%; */
    margin-top: -5%
  }
}
