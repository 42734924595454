.loader {
    --background: linear-gradient(135deg, #23C4F8, #275EFE);
    --shadow: rgba(39, 94, 254, 0.28);
    --text: #6C7486;
    --page: rgba(255, 255, 255, 0.36);
    --page-fold: rgba(255, 255, 255, 0.52);
    --duration: 3s;
    width: 200px;
    height: 140px;
    position: relative;
    margin: auto; /* Center the loader */
  }
  
  .mainloader{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: +10000000000000000003;
    background-color: rgba(255, 255, 255, 0.7);
    font-family: 'Roboto', Arial;
  }
  .loader:before,
  .loader:after {
    --r: -6deg;
    content: '';
    position: absolute;
    bottom: 8px;
    width: 120px;
    top: 80%;
    box-shadow: 0 16px 12px var(--shadow);
    transform: rotate(var(--r));
  }
  .headingmain{
    width: 100%;
    font-size: 18px;
    font-family: 'CustomFont';
    color: #23C4F8;
    margin: 0 auto;
    text-align: center;
    /* width: 100%; */
    display: block;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 20px;
    text-align: center;
  }
  .dots{
    font-family: 'Courier New', Courier, monospace;
  }
  
  .loader:before {
    left: 4px;
  }
  
  .loader:after {
    --r: 6deg;
    right: 4px;
  }
  
  .loader div {
    width: 100%;
    height: 100%;
    border-radius: 13px;
    position: relative;
    z-index: 1;
    perspective: 600px;
    box-shadow: 0 4px 6px var(--shadow);
    background-image: var(--background);
  }
  
  .loader ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
  }
  
  .loader li {
    --r: 180deg;
    --o: 0;
    --c: var(--page);
    position: absolute;
    top: 10px;
    left: 10px;
    transform-origin: 100% 50%;
    color: var(--c);
    opacity: var(--o);
    transform: rotateY(var(--r));
    animation: var(--duration) ease infinite;
  }
  
  .loader li:nth-child(2) {
    --c: var(--page-fold);
    animation-name: page-2;
  }
  
  .loader li:nth-child(3) {
    --c: var(--page-fold);
    animation-name: page-3;
  }
  
  .loader li:nth-child(4) {
    --c: var(--page-fold);
    animation-name: page-4;
  }
  
  .loader li:nth-child(5) {
    --c: var(--page-fold);
    animation-name: page-5;
  }
  
  .loader svg {
    width: 90px;
    height: 120px;
    display: block;
  }
  
  .loader li:first-child {
    --r: 0deg;
    --o: 1;
  }
  
  .loader li:last-child {
    --o: 1;
  }
  
  /* .loader span {
    display: block;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 20px;
    text-align: center;
    color: var(--text);
  } */
  
  @keyframes page-2 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    35%,
    100% {
      opacity: 0;
    }
    50%,
    100% {
      transform: rotateY(0deg);
    }
  }
  
  @keyframes page-3 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0;
    }
    65%,
    100% {
      transform: rotateY(0deg);
    }
  }
  
  @keyframes page-4 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    65%,
    100% {
      opacity: 0;
    }
    80%,
    100% {
      transform: rotateY(0deg);
    }
  }
  
  @keyframes page-5 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    80%,
    100% {
      opacity: 0;
    }
    95%,
    100% {
      transform: rotateY(0deg);
    }
  }
  