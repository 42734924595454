.main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 10% auto; */
}
/* src/components/OurBooks.module.css */
.bookContainer {
  width: 100%;
  max-width: 1340px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.mainheading{
  font-family: 'CustomFont';
  font-size: 5vw;
  font-weight: 400;
  line-height: 1.2;
  display: block;
  white-space: normal;
  word-wrap: break-word;
  /* color: #FFFFFF; */
}



/* Large phones (portrait and landscape) */
@media (max-width: 992px) {
  .main{
    margin: 0 auto;
  }
  .bookContainer{
    width: 100%;
  }
}
@media (max-width:380px){
  .mainheading{
    /* color: #79BDE4 !important; */
    font-size: 9vw;
  }
}
