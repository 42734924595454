.stepper-container {
    width: 100%;
    /* max-width: 800px; */
    margin: 2rem auto 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }
  
  .step-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }
  .step-containerlast{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
  }
  .step-container-inside{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 5px;
    width: 80%;
  }
  .step-indicator {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    border: 2px solid #ccc;
    color: #ccc;
    position: absolute;
    left: -30px;
    overflow: hidden;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .step-indicator.completed {
    background-color: #4caf50;
    border-color: #4caf50;
    color: white;
  }

  .step-indicatorlast{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    border: 2px solid #ccc;
    color: #ccc;
    position: absolute;
    left: -30px;
    overflow: hidden;
    transition: background-color 0.3s, border-color 0.3s;
    margin-bottom: 12px;
  }
  .step-indicatorlast.completed {
    background-color: #4caf50;
    border-color: #4caf50;
    color: white;
  }
  
  .tick {
    font-size: 18px;
  }
  
  .circle {
    width: 16px;
    height: 16px;
    background-color: white;
    overflow: hidden;
  }
  
  .step-label {
    font-size: 12px;
    color: gray;
    margin: 0 10px;
    font-weight: 600;
  }
  
  .progress-bar-container {
    width: 100%;
    height: 2px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin: 10px 0;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #4caf50;
    border-radius: 10px;
    transition: width 0.5s ease-in-out; /* Smooth transition */
  }
  
  .progress-bar.active {
    transition: width 1s ease-in-out; /* Longer transition for the current progress */
  }
  
  .paymentSuccessful{
    color: #2d6734;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  @media (max-width: 1024px){
    .stepper-container{
        display: none;
    }
  }