/* src/BookCard.module.css */
.card {
  position: relative;
  background-color: #FFFFFF;
  border-radius: 15px;
  padding: 16px;
  margin: 15px 15px;
  /* width: 22.5%; */
  min-height: 376px;
  height: 362px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: 0.5s;
  z-index: 998;
}
.card:hover{
  transform: scale(1.08);
}
.santacapcls{
  transform: rotateY(-22deg) rotateX(-3deg);
  position: absolute;
  top: -70px;
  right: -74px;
  z-index: +999;
}
.santacapcls img{
  width: 80%;
}

.imageContainer {
  position: relative;
    width: 240px;
    height: 240px;
    margin-bottom: 16px;
    background-image: url('../../Assets/Images/Home/bookcoverframe.webp');
    background-position: top;
    /* background-size: cover; */
    /* background-clip: border-box; */
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.image {
  display: block;
  width: 201px;
  height: 185px;
  border-radius: 24px;
  cursor: pointer;
}

.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  gap: 10px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.priceCart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  color: #DE3226;
  font-weight: bold;
}

.cart {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.stars {
  margin: 0.5rem 0;
}

@media (max-width: 992px) {
  .card{
  /* width: 33% !important; */
  margin: 2% 5px;
  }
  .imageContainer{
    width: 240px;
    background-image: none;
    height: auto;
    /* margin-bottom: 30px; */
  }
  .image {
    display: block;
    width: 100%;
    object-fit: contain;
    height: auto;
  }
  .title{
    font-size: 15px;
  }
  .cart{
    font-size: 12px;
  }
  .price{
    font-size: 12px;
  }
}

@media (max-width: 792px){
  .card{
    height: auto;
    /* width: 40% !important; */
  }
  .image {
    /* display: block; */
    width: 100%;
    object-fit: contain;
    height: auto;
  }
  .title{
    font-size: 15px;
  }
  .cart{
    font-size: 12px;
  }
  .price{
    font-size: 12px;
  }
  .details {
    gap: 8px;
  }
  /* .card{
    margin: 0;
    } */
}

@media (max-width: 667px){
  .card{
    height: auto;
    /* width: 45% !important; */
  }
}

@media (max-width: 567px){
  .card{
    height: auto;
    /* width: 55% !important; */
  }
}

@media (max-width: 467px){
  .card{
    height: auto;
    /* width: 65% !important; */
  }
}

@media (max-width: 392px){
  .card{
    height: auto;
    /* width: 75% !important; */
  }
}

@media (max-width: 367px){
  .card{
    height: auto;
    /* width: 85% !important; */
  }
}

@media (max-width: 642px){
  .card{
    height: auto;
  }
  .image {
    /* display: block; */
    width: 100%;
    object-fit: contain;
    height: auto;

  }
  .title{
    font-size: 13px;
  }
  .cart{
    font-size: 10px;
  }
  .price{
    font-size: 10px;
  }
  /* .card{
    margin: 0;
    } */
}

@media (max-width: 500px){
  .image {
    /* display: block; */
    width: 100%;
    object-fit: contain;
    height: auto;
  }
  .title{
    font-size: 10px;
  }
  .cart{
    font-size: 8px;
  }
  .price{
    font-size: 8px;
  }
  .details{
    gap: 5px;
  }
  /* .card{
    margin: 0;
    } */
}


@media (max-width: 392px){
  .image {
    /* display: block; */
    width: 100%;
    height: auto;
  }
  .title{
    font-size: 9px;
  }
  .cart{
    font-size: 7px;
  }
  .price{
    font-size: 7px;
  }
  .stars {
    width: 80px;
    height: 15px;
  }
  .card{
    padding: 10px;
  }
  .details {
    gap: 3px;
  }
  /* .card{
    margin: 0;
    } */
}

@media (max-width:1024px){
  .card{
    /* width: 28.5%; */
  }
}