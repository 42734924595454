.zoom-container {
    position: relative;
    display: inline-block;
    /* Optionally add some padding or margins */
  }
  
  .zoom-image {
    width: 100%; /* Adjust size as needed */
    height: auto;
  }
  