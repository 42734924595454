.dashboardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100vw;
    height: 100vh;
  }
  
  .optionsmain{
    width: 100%;
    max-width: 1340px;
    height: auto;
    padding: 15px;
    /* border-radius: 10px; */
    margin: 0 auto;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .btndashboard{
    padding: 10px 15px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.5s;
    cursor: pointer;
    margin: 0 0.5rem;
  }

  .btndashboard:hover{
    background-color: rgb(54, 54, 119);
  }

  .btndashboardActive{
    background-color: rgb(54, 54, 119);
  }
  
  .top{
    width: 100vw;
    background: rgb(93, 93, 184);
    /* height: 15vh; */
    margin: 0 auto;
  }
  .mainContent {
    /* display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 1340px;
    height: 85vh;
    overflow: hidden; */
    /* overflow-y: auto; */
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 1340px;
    /* height: 85vh; */
    height: 100%;
    padding: 20px;
    flex-wrap: wrap;
    overflow: hidden;
    /* overflow-y: auto; */
    /* height: 100%; */
    overflow: hidden;
    overflow-y: auto;
  }
  
  .voucherForm {
    display: flex;
    flex-direction: column;
    background: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .voucherForm input,
  .voucherForm button {
    margin-bottom: 10px;
  }
  
  .submitButton {
    background-color: #5cb85c;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .submitButton:hover {
    background-color: #4cae4c;
  }
  
  .voucherList {
    width: 50%;
  }
  
  .voucherItem {
    background: white;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .editButton {
    background-color: #0275d8;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .editButton:hover {
    background-color: #025aa5;
  }
  
  .logoutpopup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.579);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoutpopupbox{
    width: 450px;
    /* height: 100px; */
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 10px 20px;
  }
  .closeicon{
    font-size: 1.4rem;
    font-weight: bold;
    color: gray;
    cursor: pointer;
    transition: 0.5s;
    align-self: flex-end;
    background-color: lightgray;
    /* width: 30px;
    height: 30px; */
    border-radius: 50%;
    padding: 15px;
    position: relative;
  }
  .closeicon span{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .closeicon:hover{
    color: rgb(73, 72, 72);
  }
  .btns{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0.5rem auto;
  }
  .text{
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  .cancel{
    width: 100%;
    padding: 8px 15px;
    /* background-color: gray; */
    border: 1px solid gray;
    border-radius: 10px;
    cursor: pointer;
    color: gray;
    margin: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cancel:hover{
    background-color: rgb(73, 72, 72);
    color: #fff;
  }
  .logout{
    width: 100%;
    padding: 8px 15px;
    background-color: rgb(93, 93, 184);
    border-radius: 10px;
    cursor: pointer;
    color: #fff;
    margin: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logout:hover{
    background-color: rgb(54, 54, 119);
  }

 
  .textparagraph{
    color: gray;
    font-size: 0.9rem;
    margin: 0.5rem auto;
  }

  .editvoucher{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.588);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.384);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: +999;
  }