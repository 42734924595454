.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 50vh; */
    /* margin: 10% auto; */
  }

  /* src/components/OurBooks.module.css */
.mainheading{
  font-family: 'CustomFont';
  font-size: 5vw;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  color: #452565;
}

.rowContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 5% auto 0;
}
.image{
    display: block;
    width: 15%;
}
.reviews{
  /* width: 59%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviewCard{
  position: relative;
  width: 222px;
  height: 250px;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviewCardContent{
  width: 190px;
  height: 220px;
  background-color:#FFFFFF;
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 111;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.frame{
  /* width: 150px;
  height: 200px; */
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translate(-50%, -0%);
  z-index: -11;
}
.frame1{
  /* width: 150px;
  height: 200px; */
  position: absolute;
  top: -5%;
  left: 50%;
  transform: translate(-50%, -0%);
  z-index: -11;
}
.usericonContainer{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
}
.userIconitem{
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: #79BDE4;
}
.reviewParagraph{
  font-size: 12px;
  text-align: center;
  margin: 0.5rem;
}

/* Small phones (portrait and landscape) */
@media (max-width: 1300px){
  .image{
    display: none !important;
  }
}
@media (max-width: 480px) {
  
}

/* Large phones (portrait and landscape) */
@media (min-width: 481px) and (max-width: 768px) {
  .main{
    height: auto;
    margin: 10% auto;
  }

  .rowContainer {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 5% auto;
}
.image{
  display: block;
  width: 30%;
}
.reviews{
  width: 80%;
  flex-wrap: wrap;
  margin: 5% auto;
}
.reviewCard{
  margin: 2% 0;
}
}

/* Tablets (portrait and landscape) */
@media (min-width: 769px) and (max-width: 1024px) {
  .main{
    height: auto;
    margin: 10% auto;
  }
  .rowContainer {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 5% auto;
}
.image{
  display: block;
  width: 30%;
}
.reviews{
  width: 60%;
  flex-wrap: wrap;
  margin: 5% auto;
}
.reviewCard{
  margin: 2% 0;
}
}

/* Laptops (small screens) */
@media (min-width: 1025px) {
  /* .main{
    height: auto;
    margin: 10% auto;
  }
 .rowContainer{
  flex-direction: column;
  width: 100%;
 } */
}
@media (max-width: 548px){
  .reviews{
    flex-direction: column;
  }
}


