.OTPConfirmationModelMain{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.otpForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.errorMsg{
    color: red;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 79%;
    font-size: 13px;
    margin-top: 6px;
}


.confirmationModel{
    position: relative;
    background-color: #fff;
    width: 600px;
    height: 45%;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.otpMsg{
    font-size: 14px;
}
.otpHeading{
    font-size: 18px;
    margin: 0.5rem 0;
}
/* .otpForm input{
    margin: 1rem auto;
} */
.confirmBtn{
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #5F5BCD;
    color: #fff;
    border: none;
    border-radius: 1rem;
    margin: 0 auto;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: background-color 0.3s ease;
}
.confirmBtnDisableCls{
    display: block;
    width: 60%;
    padding: 10px;
    background-color: #E6E6E6;
    color: lightgray;
    border: none;
    border-radius: 1rem;
    margin: 0 auto;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    cursor: not-allowed;
    transition: background-color 0.3s ease;
}
.resendBtn{
    font-size: 14px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 1rem;
}
.timer{
    font-size: 12px;
    color: gray;
    cursor: pointer;
    margin-top: 1rem;
}
.resendBtn:hover{
    color: rgb(86, 86, 236);
}
.closeModel{
    position: absolute;
    top: 1.5%;
    right: 2.5%;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.otpMessageInput{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem auto;
    justify-content: center;
    align-items: center;
    
}
.otpInput {
    width: 80%; /* Increased width for the input field */
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc; /* Border for the input field */
    border-radius: 0.5rem;
   
    outline: none; /* Remove default outline */
    transition: border-color 0.3s ease;
}

  /* src/StyledButton.css */
  .styledButton {
    background: linear-gradient(rgb(130, 193, 200), rgb(107, 156, 161));
    border: none;
    border-radius: 25px;
    color: white;
    padding: 5px 5px;
    font-size: 16px;
    width: 50%;
    height: 42px;
    cursor: pointer;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    transition: transform 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .styledButton:hover {
    transform: scale(1.05);
  }
  
  .styledButton:active {
    transform: scale(0.95);
  }
  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid white;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.iconDivLock{
    display: flex;
}
  .innerButton{
    position: relative;
    /* width: 92%; */
    height: 100%;
    /* background-color: #A774DD; */
    /* border: 1px solid #A774DD; */
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    align-items: center;
    color: #fff;
    /* box-shadow: inset 0 -3px 5px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1); */
    box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.1);
  }


/* Media Query for tablets */
@media (max-width: 768px) {
    .confirmationModel {
        width: 75%; 
        padding: 0.75rem; 
    }
}

/* Media Query for mobile devices */
@media (max-width: 480px) {
    .confirmationModel {
        width: 90%; 
        padding: 0.5rem; 
    }
}