.PrivacyPolicy{
    width: 100vw;
    height: 100%;
}
.mainContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.heading{
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color:#47826D;
    margin: 0 auto;
    text-align: center;
    /* padding-top: 50px; */
    padding: 15px;
}

.content{
  width: 50%;
  height: 100%;
  position: relative;
}
.microscope{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}
.microscope img{
    width: 40%;
    display: block;
}
.paragraph{
    font-family: 'Textfontfamily';
    font-size: 1.2vmax;
    text-align: justify;
    text-align-last: center;
    margin: 2rem auto 0;
}
.uolist{
    list-style-position: inside;
    padding: 1rem;
}
.uolist li{
    font-family: 'Textfontfamily';
    font-size: 1.2vmax;
    text-align: justify;
    /* text-align-last: center; */
    margin: 0.8rem auto 0;
    /* padding-left: -1rem; */
    list-style-type: none;
}
.uolist li .uolistheading{
    font-family: 'CustomFont';
    font-weight: normal;
    color: #47826D;
    font-size: 1.4vmax;
}
.subheading{
    font-family: 'CustomFont';
    text-align: center;
    line-height: 1.2;
    margin: 0.8rem 0 0;
    font-size: 2vmax;
    font-weight: normal;
    color: #47826D;
}
.HomeContactus {
    background-image: url('../../Assets/Backgrounds/PrivacyPolicy/privacypolicy.webp');
    min-width: 100%;
    min-height: 125vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  .Footer{
    background-image: url('../../Assets/Backgrounds/Footer/footerbackground.webp');
    width: 100vw;
    /* height: 40%; */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
    position: absolute;
    bottom: -5%;
    left: 0%;
  }

  @media (max-width:768px){
    .content{
        width: 90%;
    }
    .paragraph{
        font-size: 15px;
    }
    ul li{
        font-size: 12px;
    }
    .HomeContactus{
        min-height: 100vh;
    }
  }

  @media (max-width:392px){
    .content{
        width: 90%;
    }
    .paragraph{
        font-size: 12px;
    }
    ul li{
        font-size: 10px;
    }
    .HomeContactus{
        min-height: 80vh;
    }
  }