.main{
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* justify-content: center; */
    align-items: center;
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 999;
  }
  /* src/components/OurBooks.module.css */
  .bookContainer {
    display: flex;
    gap: 70px;
    width: 75%;
    /* height: 50vh; */
    /* justify-content: center; */
    /* align-items: center; */
    /* flex-wrap: wrap; */
    margin: 0 120px;
    padding: 0 15px;
    margin-bottom: 5%;
  }
  
  .mainheading{
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    display: blockbookContainer;
    white-space: normal;
    word-wrap: break-word;
    color: #2DC7FB;
    margin: 0 auto;
    padding: 15px;
  }
  .profileTopLeft {
    display: flex;
    flex-direction: column;
    gap: 9px;
    border: 2px solid #B6B6B6;
    padding: 20px;
    border-radius: 20px;
    height: auto;
    align-items: center;
  }
  .profileTopRight {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 2px solid #B6B6B6;
    border-radius: 20px;
    padding: 30px;
  }
  .profileTopLeftContainer{
    /* display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    height: 450px */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: max-content; */
    gap: 10px;
    height: 450px;
    width: 25%;
    position: relative;
  }
  .checkoutText {
    color: #2DC7FB;
    font-size: 18px;
    cursor: pointer;
    /* font-family: 'CustomFont';
    font-weight: 400; */
  }
  .leftTitle{
    color: #2DC7FB;
    font-size: 30px;
    text-align: center;
    font-family: 'CustomFont';
    font-weight: 400;

  }

  .leftBtnsContainer {
    border: 2px solid #B6B6B6;
    width: 100%;
    /* border: 2px solid #ffffff; */
    border-radius: 15px;
    padding: 12px 0;
    color: black;
    font-size: 12px;
    /* width: 256px;
    height: 58px; */
    text-align: center;
    position: relative;
    z-index: +999;
  }
  .labelText{
    color: #B6B6B6;
    width: max-content;
    font-size: 13px;
  }
  .labelTextdiscount{
    color: rgba(76, 0, 130, 0.514);
    width: max-content;
    font-size: 12px;
  }
  .leftBtns {
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    border: 2px solid #ffffff;
    border-radius: 15px;
    padding: 12px 0;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}


.logoutpopup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.579);
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoutpopupbox{
  width: 450px;
  /* height: 100px; */
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px 20px;
}
.closeicon{
  font-size: 1.4rem;
  font-weight: bold;
  color: gray;
  cursor: pointer;
  transition: 0.5s;
  align-self: flex-end;
  background-color: lightgray;
  /* width: 30px;
  height: 30px; */
  border-radius: 50%;
  padding: 15px;
  position: relative;
}
.closeicon span{
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.closeicon:hover{
  color: rgb(73, 72, 72);
}
.btns{
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem auto;
}
.text{
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
.cancel{
  width: 100%;
  padding: 8px 15px;
  /* background-color: gray; */
  border: 1px solid gray;
  border-radius: 10px;
  cursor: pointer;
  color: gray;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel:hover{
  background-color: rgb(73, 72, 72);
  color: #fff;
}
.logout{
  width: 100%;
  padding: 8px 15px;
  background-color: rgb(93, 93, 184);
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout:hover{
  background-color: rgb(54, 54, 119);
}


.textparagraph{
  color: gray;
  font-size: 0.9rem;
  margin: 0.5rem auto;
}

.promocodetitle{
  font-size: 12px;
  font-weight: bold;
  color: gray;
}

.voucherInput{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.2rem auto;
}
.voucherInputactiveinput{
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: 1px solid gray;
  width: 100%;
}

.voucherInputinactive{
  padding: 10px;
  border-radius: 10px;
  outline: none;
  color: lightgrey;
  font-weight: bold;
  border: 1px solid lightgray;
  width: 90%;
}
.voucherInputinactive:hover{
  cursor: not-allowed;
}

.voucherInput input:focus{
  border: 1px solid indigo;
}
.claimbutton{
  width: 100%;
  margin: 0.5rem auto;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: rgb(93, 93, 184);
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
}

.claimbutton:hover{
  background-color: rgb(54, 54, 119);
}

.appliedbtn{
  width: 100%;
  margin: 0.5rem auto;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: lightgray;
  color: gray;
  transition: 0.5s;
  cursor: not-allowed;
}

.inputandclear{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clearBtn{
  /* width: fit-content; */
  margin-left: 0.5rem;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 50%;
  border: none;
  background-color: rgb(93, 93, 184);
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
}
.clearBtn:hover{
  background-color: rgb(54, 54, 119);
}


/* Container */
.countryselectorcontainer {
  /* border: 2px solid #B6B6B6; */
  width: 100%;
  /* border: 2px solid #ffffff; */
  border-radius: 15px;
  /* padding: 12px 0; */
  /* color: black; */
  color: gray;
  font-size: 12px;
  /* width: 256px;
  height: 58px; */
  text-align: center;
  position: relative;
  z-index: +999;
}

/* Select Dropdown */
.countryselect {
  width: 100%;
  padding: 12px;
  /* font-size: 1rem; */
  color: #333;
  border: 2px solid #B6B6B6; /* Your theme color */
  border-radius: 15px;
  background-color: #f9f9f9;
  outline: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

/* Hover State */
.countryselect:hover {
  border-color: #6f3cae; /* Darker shade of theme color */
  background-color: #f3f1f9;
}

/* Focus State */
.countryselect:focus {
  border-color: #5c3099; /* More intense shade */
  box-shadow: 0 0 5px rgba(140, 90, 190, 0.5);
}

/* Option Styles */
.countryselect option {
  padding: 10px;
  font-size: 1rem;
}


@media (max-width: 768px){
  .bookContainer{
    margin: 0 !important;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
  }
  .profileTopLeftContainer{
    width: 90%;
  }
  .leftBtns{
    border: 2px solid #452565;
    color: #ffffff;
    font-weight: 800;
  }
  .leftTitle{
    color: #452565;
    font-size: 15px;
  }
  .profileTopLeft{
    flex-direction: row;
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .leftBtnsContainer{
    font-size: 12px;
    /* padding: 12px 5px; */
    height: auto;
  }
  .labelText{
    font-size: 12px;
  }
}

@media  (max-width: 480px){
.mainheading{
  font-size: 7vw;
}
}