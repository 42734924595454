/* .main {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  
  .heading {
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    margin: 2% auto;
    display: block;
    white-space: normal;
    word-wrap: break-word;
  }
  .input{
    width: 60%;
    outline: none;
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 1rem;
    border: none;
    margin: 3% auto;
  }
  .signup{
    font-family: 'Textfontfamily';
    font-size: 18px;
    font-weight: 400;
    width: 50%;
    border-radius: 24px;
    color: #FFFFFF;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3% auto;
    cursor: pointer;
  }

  @media (max-width:767px){
    .signup{
      font-size: 15px;
      padding: 1rem;
      width: 45%;
    }
  }

  @media (max-width:510px){
    .main{
      width: 90%;
    }
    .signup{
      font-size: 12px;
      padding: 0.7rem;
      width: 50%;
    }
    .input{
      width: 100%;
      z-index: +111;
    }
    .signup{
      width: 70%;
    }
  } */


  .main{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5% auto; */
    /* margin-bottom: 145px; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mainheading{
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    /* color: #7ABDE3; */
    color: #fff;
    padding-top: 50px;
  }
  .homeCountactusform{
    width: 200px;
    height: 60px;
    background:rgb(156 102 212);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .paragraph{
    /* width: 35%; */
    /* color: #000000;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    margin: 1rem auto; */
    font-family: 'Textfontfamily';
    line-height: 1.7;
    font-size: 14px;
    text-align: center;
    margin: 0.5rem auto 0;
    color: #fff;

  }
  .error{
    font-size: 12px;
    color: red;
  }
.email{
  color: blue;
}
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
    /* max-width: 600px; */
    margin: 4% auto;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .rowbtn {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 0 auto;
  }
  
  .field {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .field label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    /* color: #452565; */
    color: #fff;
  }
  
  .field input,
  .field textarea {
    padding: 0.5rem;
    border: 2px solid #BABABA;
    outline: none;
    border-radius: 1rem;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  .field textarea {
    height: 100px;
  }
  
  .sendButton {
    align-self: flex-end;
    width: fit-content;
    height: 51px;
    background-color: transparent;
    /* border: none; */
    cursor: pointer;
    /* margin-left: auto; */
    margin: 0.5rem auto;
    /* padding: 0.75rem 1.5rem; */
    /* background-color: #007BFF; */
    /* color: #fff; */
    /* border: none; */
    /* border-radius: 4px; */
    /* cursor: pointer; */
  }
  
  /* .sendButton:hover {
    background-color: #0056b3;
  } */
  

  


/* Large phones (portrait and landscape) */
@media (max-width: 768px) {
  .main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5% auto;
    width: 95%;
  }
  .form {

    padding: 0px 15px;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 2% auto;
}
.paragraph{
  width: 100%;
  font-size: 14px;
}
  
}

@media (max-width:380px){
  .form{
    padding: 0 15px;
  }
  .paragraph{
    font-size: 10px;
  }
  .field label {
    font-size: 12px;
  }
}