.main{
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
}
.top{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 15px;
}
.heading{
    font-family: 'CustomFont';
    font-size: 3vw;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color:#55AEB8;
}
.toggle{
    width: 30%;
    border: 1.5px solid #82c1c8;
    /* padding: 0.5rem 1rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    margin: 1rem auto;
    overflow: hidden;
}
.inputfieldGroup {
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.common{
    /* background-color: #82C1C8;
    color: #fff; */
    padding: 0.7rem 1rem;
    width: 50%;
    text-align: center;
    border-radius: 25px;
    font-family: 'Textfontfamily';
    font-weight: bold;
    cursor: pointer;
    /* color: #82C1C8; */
    transition: 0.3s;
}
.active{
    background-color: #82C1C8;
    color: #fff;
    /* color: #fff;
    padding: 0.7rem 1rem;
    width: 50%;
    text-align: center;
    border-radius: 1rem;
    font-family: 'Textfontfamily';
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s; */  
}
.notactive{
    background-color: #fff;
    color: #82C1C8;
}
.form{
    width: 40%;
    height: 100%;
    display: flex;

    flex-direction: column;
    justify-content: space-between;
}
.UserProfilemain {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 10vh; */
    margin: 0.5rem auto;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid lightgray;
}
.UserProfilemainupdateimage{
    width: 80px;
    height: 80px;
    /* border: 1px solid black; */
    border-radius: 50%;
    overflow: hidden;
}
.UserProfilemainupdateimage img{
    width: 100%;
    height: 100%;
    display: block;
}
.inputcls{
    outline: none;
    border: 2px solid #BABABA;
    padding: 0.8rem;
    border-radius: 24px;
    margin: 0.5rem 0;
}
.firstlastname{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.firstlastname input{
    width: 48%;
}
.profilepicture{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fileInput{
    border: none;
}

.Forgotpassword{
    margin: 0 0 1rem 0;
    color: #55AEB8;
    cursor: pointer;
    width: fit-content;
    overflow: hidden;
}
.btn{
    /* margin-left: auto; */
    cursor: pointer;
    margin: 0.4rem auto;
}
.bottom{
    min-width: 100%;
    min-height: 100vh; /* Ensure this covers the entire viewport height */
    background-image: url('../../Assets//Images/SigninSignup/greenhills.webp');
    background-size: cover; /* Ensure the background image covers the entire container */
    background-repeat: no-repeat;
    background-position: center;
    display: flex; /* Use flexbox to center content */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    position: relative;
}
.Footer{
    background-image: url('../../Assets/Backgrounds/Footer/footerbackground.webp');
    width: 100vw;
    /* height: 40%; */
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
    position: absolute;
    bottom: -5%;
    left: 0%;
  }
  .popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: +11111111111;
    /* background-color: rgba(255, 255, 255, 0.7); */
    /* background-color: rgba(255, 255, 255, 0.37); */
  }
  .error{
    font-size: 12px;
    color: red;
    margin-left: 10px;
  }
  .inputsignupclass{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .inputsignupclass input{
    width: 100%;
  }
  .inputsignupclass:last-child{
    margin-left: 0.5rem;
  }
  .popupbox{
    margin-top: 5%;
    width: 60vw;
    height: 70vh;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius: 2rem;
    z-index: +1111;
  }

  /* src/StyledButton.css */
.styledButton {
    background: linear-gradient(rgb(130, 193, 200), rgb(107, 156, 161));
    border: none;
    border-radius: 25px;
    color: white;
    padding: 5px 5px;
    font-size: 16px;
    width: 155px;
    height: 42px;
    cursor: pointer;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    transition: transform 0.2s;
  }
  
  .styledButton:hover {
    transform: scale(1.05);
  }
  
  .styledButton:active {
    transform: scale(0.95);
  }
.iconDivLock{
    display: flex;
}
  .innerButton{
    position: relative;
    /* width: 92%; */
    height: 100%;
    /* background-color: #A774DD; */
    /* border: 1px solid #A774DD; */
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    align-items: center;
    color: #fff;
    /* box-shadow: inset 0 -3px 5px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1); */
    box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* @media (max-width: 1200px){
    .toggle{
        width: 60%;
    }
    .form{
        width: 100%;
        padding: 0px 15px;
    }
  } */


  /* Media Queries */
@media (max-width: 768px) {
    .heading {
        font-size: 5vw; /* Larger font size for small screens */
    }

    .toggle {
        width: 80%;
    }

    .form {
        width: 90%;
        padding: 0 1rem;
    }

    .firstlastname {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .inputsignupclass input{
        width: 100%;
    }
    .inputsignupclass:last-child{
        margin-left: 0;
      }
}

@media (max-width: 480px) {
    .heading {
        font-size: 7vw; /* Even larger font size for very small screens */
    }

    .form {
        padding: 0;
    }
    
}