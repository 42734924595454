.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.452);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.heading{
  font-family: 'CustomFont';
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2;
  display: block;
  white-space: normal;
  word-wrap: break-word;
  color:#7ABDE3;
  margin: 0 auto;
  /* padding-top: 50px; */
  padding: 15px;
}

.modalContent {
  background: #fff;
  backdrop-filter: blur(10px);
  padding: 25px;
  border-radius: 12px;
  max-width: 620px;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.modalHeader {
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
}

.modalBody {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.modalBody p{
  margin: 0.5rem auto;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 15px;
}

.optionButton {
  padding: 12px;
  border: 2px solid #ddd;
  color: #452565;
  border-radius: 50px;
  background: #f9f9f9;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.optionButton:hover {
  background: #f0f0f0;
  border-color: #452565;
}

.selected {
  border-color: #452565;;
  font-weight: 600;
  color: #452565;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.cancelButton {
  background: transparent;
  border: 2px solid #7ABDE3;
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancelButton:hover {
  background: #ddd;
}

.confirmButton {
  background: #7ABDE3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.confirmButton:disabled {
  background: #ccc;
  cursor: not-allowed;
}


/* Responsive Styles */

@media (max-width: 768px) {
  .modalContent {
    padding: 20px;
    max-width: 90%;
  }

  .heading {
    font-size: 1.4rem;
  }

  .modalBody {
    font-size: 0.95rem;
  }

  .optionButton {
    padding: 10px;
    font-size: 0.9rem;
  }

  .cancelButton, .confirmButton {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .modalFooter {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .confirmButton {
    width: 100%;
  }

  .cancelButton {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.2rem;
  }

  .optionButton {
    padding: 8px;
    font-size: 0.85rem;
  }

  .cancelButton, .confirmButton {
    padding: 6px 14px;
    font-size: 0.85rem;
  }
}