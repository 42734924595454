.HomeContainer{
    height: 100%;
    position: relative;
}
.mainpage{
    max-width: 1340px;
    /* height: 90vh; */
    /* height: 100%; */
    padding: 15px 5%;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    margin: 0 auto;
}
.loadermain{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
}
.mainpageLeft{
  width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 30px;
}
.heading{
    font-family: 'CustomFont';
    font-size: 6vw;
    /* font-size: 22px; */
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color:#79BDE4;
    padding-top: 5%;
    /* padding: 8% 0; */
}
.apostrophe{
  font-family: 'Textfontfamily';
}
.CreateYourOwnBook{
    width: fit-content;
    cursor: pointer;
    margin-top: 50px;
    overflow: hidden;
    border-radius: 50px;
    transition: 0.5s;
    /* border: 1px solid black; */
}
.CreateYourOwnBook:hover{
  transform: scale(1.1);
}
.mainpageRight{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
}
.mainpageRight img{
    max-width: 100%;
    height: auto;
    margin-top: auto;
}

.OurBooks::before {
  content: '';
  /* padding: 100px 0; */
  width: 100%;
  height: 140vh;
  background-image: url('../../Assets/Backgrounds/Home/Home.webp');
  background-size: cover; /* Ensure the background image covers the entire container */
  background-repeat: no-repeat;
  background-position: center;
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}


  .OurBooks {
    position: relative;
}
  
.Aboutus {
  /* min-width: 100%;
  min-height: 100%;
  background-image: url('../../Assets/Backgrounds/Home/AboutusBg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative; */
  width: 100%;
  /* min-height: 140vh; */
  background-image: url('../../Assets/Backgrounds/Home/AboutusBg.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 120vh;
}
.aboutuscharacter{
  display: block;
  width: 20%;
  position: absolute;
  bottom: 18%;
  left: 50%;
  transform: translate(-50%, 18%);
}
.ourbestsellers {
  min-width: 100%;
  min-height: 140vh;
  /* padding: 200px 0px; */
  background-image: url('../../Assets/Backgrounds/Home/ourbestseller.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -50px;
}
.viewallbooks{
  font-family: 'Textfontfamily';
  font-size: 18px;
  font-weight: 400;
  width: 135px;
  background-color: #452565;
  border-radius: 24px;
  color: #FFFFFF;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 3% auto;
  padding: 0.7rem 2rem;
  cursor: pointer;
  transition: 0.5s;
}
.viewallbooks:hover{
  transform: scale(1.1);
}
.reviews{
  min-width: 100%;
  /* min-height: 80vh; */
}
.HomeContactus {
  min-width: 100%;
  min-height: 122vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
}
.HomeContactus::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../../Assets/Backgrounds/Home/HomeContactus.webp');
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  bottom: -190px;
}

  /* Styles for Webkit-based browsers */
::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Height of the scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #ccc; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners of the scrollbar thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #999; /* Color of the scrollbar thumb on hover */
  }
  /* .homeFooter{
    margin-top: -320px;
  } */
  

/* Tablets (portrait and landscape) */
@media  (max-width: 992px) {
  .mainpage{
    height: auto;
    padding: 5%;
  }
  .heading {
    font-size: 6vw; /* Adjust font size */
    /* margin-bottom: 10vw; */
    /* text-align: center; */
  }
  .Aboutus {
    min-height: auto;
  }
  .ourbestsellers {
    min-height: auto;
    margin: 10px auto 10%;
  }
  .rowContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 5% auto 0;
  }
  .HomeContactus {
    /* min-height: 0; */
    background-image: none;
  }
  .homeFooter{
    margin-top: 0px;
  }
  .mainpageLeft{
    padding-top: 0 !important;
  }
}

/* Large phones (portrait and landscape) */
@media  (max-width: 768px) {
  .mainpage{
    height: auto;
    padding: 5%;
    display: flex;
    flex-direction: column;
  }
  .heading {
    font-size: 16vw; /* Adjust font size */
    /* margin-bottom: 10vw; */
    text-align: center;
  }
  .mainpageLeft{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Aboutus {
    min-height: auto;
  }
  .aboutuscharacter {
    width: 30%;
  }
  .ourbestsellers {
    min-height: auto;
    margin: -50px auto 10%;
  }
  .HomeContactus {
    min-width: 100%;
    min-height: 120vh;
    padding-top: 10px;
    /* min-height: 80%; */
  }
  /* .HomeContactus::before{ 
    background-size: contain;
    bottom: -390px;
  } */
  /* .Footer{
    position: absolute;
    bottom: 0%;
    left: 0%;
  } */
}





@media (max-width:567px){
  .mainpage{
    gap: 35px;
  }
  /* .heading{
    font-size: 22px;
  } */
  .OurBooks:before{
    /* padding: 50px 0; */
    height: 100vh;
  }
  .mainheading{
    color: #79BDE4;
  }
  .form{
    width: 90%;
  }
}

@media (max-width:426px){
  /* .heading{
    font-size: 18px;
  } */
  .HomeContactus {
    min-width: 80%;
  }
  .mainheading{
    color: #79BDE4;
  }
  .ourbestsellers {
    min-height: auto;
    margin: 0px auto 10%;
  }
  .form{
    width: 90%;
  }
  .OurBooks:before{
    /* padding: 50px 0; */
    height: 100vh;
  }
}

@media(min-width: 1480px){
  .mainpage {
    max-width: 1340px;
    height: 65vh;
    /* height: 100%; */
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
  .heading{
    font-family: 'CustomFont';
    font-size: 10vmin;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color: #79BDE4;
}
.mainpageLeft {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 30px;
}
.CreateYourOwnBook {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  /* padding-top: 50px; */
}
.mainpageRight {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mainpageRight img {
  max-width: 100%;
  height: auto;
  margin-top: 0;
}
}
