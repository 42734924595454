*{
  padding: none;
  margin: 0;
  box-sizing: border-box;
}
.App{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* overflow-y: auto; */
}
.navbarmain{
  height: 80px;
}
.mainContentDiv{
  width: 100vw;
  /* height: 100vh; */
  height: calc(100vh - 80px);
  overflow: hidden;
  overflow-y: auto;
}

.mainContentDivforadmin{
  width: 100vw;
  /* height: 100vh; */
  height: 100vh;
  overflow: hidden;
}

@media(max-width: 786){
  .navbarmain{
    height: 0px;
  }
}
 
/* .customConter{
  max-width: 1024px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
} */
