.profileTopRight {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 2px solid #BACD53;
    border-radius: 20px;
    padding: 30px;
    width: 650px;
    height: 60vh;
  }
  
  .rightTitle {
    font-family: 'CustomFont' !important;
    font-weight: normal;
    color: #BACD53;
    font-family: JELLYFRUIT;
    font-size: 30px;
  }
  
  .insameline{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ribbon {
    width: max-content;
    background: #2DC7FB; /* Change to your preferred color */
    color: white;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

  .profileRightContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    /* width: 500px; */
    padding: 30px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  
  .rightSubTitle {
    color: #452565;
    font-size: 22px;
  }
  
  .rightPara {
    color: #FFFFFF;
    width: 458px;
    text-align: center;
    padding: 0 24px;
  }
  
  .leftBtnsContainer {
    width: 280px;
  }
  
  .leftBtns {
  
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    border: 2px solid #ffffff;
    border-radius: 15px;
    padding: 12px 0;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
  
  .orderItem {
    display: flex;
    align-items: center;
    gap: 15px;
    background: white;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
  }
  
  .itemDetailContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
  }
  
  .itemTop {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
  }
  
  .itemBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .date {
    color: #B6B6B6;
    font-size: 12px;
  
  }
  
  .priceContainer {
    font-size: 14px;
    color: #B6B6B6;
  
  }
  
  .price {
    color: #DE3226;
  }
  
  .itemImg {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }
  
  .itemTitle {
    font-size: 22px;
    font-weight: 400;
  }
  
  .loaderDiv{
    display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 64%;
  }
  
  .loader {
    border: 4px solid rgb(187 205 82);
    border-radius: 50%;
    border-top: 4px solid white;
    width: 45px;
    height: 45px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  .starsContainer {
    display: flex;
    align-items: center;
    gap: 2px;
  }
  
  @media (max-width:1024px) {
    .itemTitle {
      font-size: 15px;
    }
  
    .rightTitle {
      font-size: 25px;
    }
  
    .priceContainer {
      font-size: 12px;
    }
  
    .profileTopRight {
      width: 500px;
    }
  }
  
  @media (max-width: 768px) {
    .profileTopRight {
      width: 100%;
    }
  
    .profileRightContent {
      /* padding: 30px 0px; */
    }
  
    .date {
      font-size: 10px;
    }
  
    .priceContainer {
      font-size: 10px;
    }
  
    .itemImg {
      width: 70px;
      height: auto;
    }
    .itemTop{
      gap: 10px;
    }
    .itemBottom{
      gap: 15px;
    }
    .itemDetailContainer{
      gap: 20px;
    }
  }
  
  @media (max-width:425px){
    .itemImg {
      width: 60px;
      height: auto;
    }
    .itemTitle{
      font-size: 12px;
    }
    .itemTop{
      gap: 8px;
    }
    .itemDetailContainer{
      gap: 15px;
    }
    .rightTitle{
      text-align: center;
    }
    .profileTopRight{
      padding: 30px 0;
    }
  }
  
  @media (max-width:375px){
    .profileRightContent{
      padding: 10px;
    }
  }@media (max-width:320px){
    .itemImg{
      width: 50px;
      height: auto;
    }
    .priceContainer{
      font-size: 8px;
    }
    .date{
      font-size: 8px;
    }
    .rightTitle{
      font-size: 22px;
    }
  }