/* .navbar {
    font-family: 'Gilroy-Medium';
    background-color: #7ABDE3;
    height: 80px;
    display: flex;
  }
  .navbartop{
    width: 100%;
    max-width: 1340px;
    padding-right:  .75rem;
    padding-left: .75rem;
    margin-right: auto;
    margin-left: auto;     
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .link {
    font-size: 15px;
  }
  .Logo{
    display: block;
    cursor: pointer;
    z-index: +111111111111111111;
    margin-top: 70px;
    width: 170px;
    position: relative;
  }

  .options{
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .options span{
    font-size: 14px;
    color: #FFFFFF;
    font-family: 'Textfontfamily';
    cursor: pointer;
  }
  .navbarBottom{
    width: 50%;
    height: 50%;
    margin: 0 5% 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inputSearchContainer{
    width: 100%;
    height: fit-content;
    border: 0.5px solid lightgray;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;

  }
  .inputSearch{
    width: 100%;
    padding: 1%;
    border: none;
    outline: none;
    background-color: transparent;
  }
.cart{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 10px;
}
.userOptions{
  position: relative;
}
.optionDiv{
  position: absolute;
  top: 120%;
  right: 25%;
  width: 150px;
  height: 100px;
  background-color: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.5rem;
  z-index: +11111111111111111;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.optionItem{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}
.optionItemIcon{
  color: #7ABDE3;
}
.optionItem:hover {
  background-color: #7ABDE3;
}
.optionItem:hover .optionItemIcon{
  background-color: #7ABDE3;
  color: #fff;
}

  .user{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
  }
  .userIcon{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .cartAmount{
    background-color: red;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    position: absolute;
    left: 42px;
    top: 20px;
    position: absolute;
  }


@media (max-width: 768px) {
  .Logo{
    display: block;
    width: 110px;
    margin-top: 5%;
    cursor: pointer;
    z-index: +111111111111111111;
  }
  .options{
    width: 60%;
    justify-content: space-around;
  }
  .options span{
    font-size: 12px;
  }
}

@media (max-width: 442px){
  .Logo{
    width: 80px;
  }
  .options span{
    font-size: 10px;
  }
}

@media (max-width:380px){
  .navbar{
    height: 55px;
  }
  .Logo{
    width: 55px;
  }
  .options span{
    font-size: 8px;
  }
  .user{
    width: 30px;
    height: 30px;
  }
  .cartContainer{
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
  }
  .subheading {
    font-size: 15px;
  }
  .mainheading{
    margin-top: 10px;
  }
  .signup{
    font-size: 10px;
  }
  .main{
    width: 100%;
  }

} */


/* Navbar Styles */
/* 
.navbar {
  font-family: 'Gilroy-Medium';
  background-color: #7ABDE3;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.navbartop {
  width: 100%;
  max-width: 1340px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  font-size: 15px;
}

.Logo {
  display: block;
  cursor: pointer;
  width: 100px;
  margin-top: 50px;
}

.options {
  display: flex;
  align-items: center;
  gap: 20px;
}

.options span {
  font-size: 14px;
  color: #FFFFFF;
  font-family: 'Textfontfamily';
  cursor: pointer;
}

.cart {
  display: flex;
  align-items: center;
  gap: 10px;
}

.userOptions {
  position: relative;
}

.optionDiv {
  position: absolute;
  top: 120%;
  right: 0;
  width: 150px;
  background-color: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.5rem;
  z-index: 11111111;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.optionItem {
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}

.optionItemIcon {
  color: #7ABDE3;
}

.optionItem:hover {
  background-color: #7ABDE3;
  color: #fff;
}

.user {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.userIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cartAmount {
  background-color: red;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: absolute;
  left: 42px;
  top: 20px;
}

@media (max-width: 768px) {
  .Logo {
      width: 110px;
  }

  .options {
      display: none;
  }

  .hamburgerMenu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 25px;
      height: 20px;
      cursor: pointer;
  }

  .hamburgerMenu div {
      height: 3px;
      background-color: #fff;
      border-radius: 5px;
  }

  .mobileMenu {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      background-color: #7ABDE3;
      padding: 10px 0;
      z-index: +11111111111111111111111111111111111;
  }
  .mobileMenu span:last-child{
    border-bottom: none;
  }

  .mobileMenu span {
      font-size: 18px;
      padding: 20px;
      cursor: pointer;
      color: #FFFFFF;
      border-bottom: 1px solid #FFFFFF;
  }

  .mobileMenu span:hover {
      background-color: #6AA0C9;
  }
}

@media (max-width: 442px) {
  .Logo {
      width: 80px;
  }
  .user {
      width: 30px;
      height: 30px;
  }
  .cartContainer {
      width: 20px;
      height: 20px;
  }
}

@media (max-width: 380px) {
  .navbar {
      height: 55px;
  }
  .Logo {
      width: 55px;
  }
  .cartAmount {
      left: 30px;
      top: 10px;
  }
} */


/* Navbar Styles */
.navbar {
  font-family: 'Gilroy-Medium';
  /* background-color: #7ABDE3; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  position: relative;
}

.navbartop {
  height: 100%;
  width: 100%;
  max-width: 1340px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.middleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.link {
  font-size: 15px;
}

.Logo {
  display: block;
  cursor: pointer;
  width: 140px;
  margin-top: 50px;
  z-index: 999;
  position: relative;
}

.options {
  display: flex;
  align-items: center;
  gap: 20px;
}

.options span {
  font-size: 14px;
  color: #FFFFFF;
  font-family: 'Textfontfamily';
  cursor: pointer;
}

.cart {
  display: flex;
  align-items: center;
  gap: 10px;
}

.userOptions {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  /* margin-top: 0.5rem; */
}


.optionDiv {
  position: absolute;
  top: 120%;
  right: 0;
  width: 150px;
  background-color: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.5rem;
  z-index: 11111111;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.optionItem {
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  gap: 10px;
}

.optionItemIcon {
  color: #7ABDE3;
}

.optionItem:hover {
  background-color: #7ABDE3;
  color: #fff;
}

.optionItem:hover .optionItemIcon {
  /* background-color: #fff; */
  color: #fff;
}

.user {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
}

.userIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: #fff;
}

.userIconWhite {
  width: 30px;
  height: 30px;
  object-fit: cover;
  color: #fff;
}

.cartContainer {
  position: relative;
}
.cartAmount {
  background-color: red;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: absolute;
  left: 17px;
  top: -15px;
}

.link {
  padding: 10px 15px;
  cursor: pointer;
  position: relative;
}

.link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff; /* or whatever color you want for the underline */
  transition: width 0.3s ease-in-out;
}

.link:hover::after {
  width: 100%;
}

.active {
  font-weight: bold;
}

.active::after {
  width: 100%;
}

.logowrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 142px;
  height: 149px;
  overflow: hidden;
}
/* Media Queries for Responsiveness */

/* Small phones (portrait and landscape) */
@media (max-width: 768px) {
  .Logo {
    width: 110px;
  }
  .logowrapper {
    flex: 1;
    justify-content: flex-start;
  }
  .options {
    display: none;
  }
  .userOptions{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 20px;
  }
.userOptions p{
  font-size: 18px;
  color: #fff;
  margin-right: 10px;

}
.user{
  width: 80px;
  height: 80px;
  border-radius: 50%;
    overflow: hidden;
}
  .hamburgerMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    cursor: pointer;
    order: 3;
    margin-left: 20px;
  }

  .hamburgerMenu div {
    height: 3px;
    background-color: #fff;
    border-radius: 5px;
  }

 

  .userIconText {
    font-size: 14px !important; 
    color: #FFFFFF;
    font-family: 'Textfontfamily';
    cursor: pointer;
  }

  .cart {
    order: 2;
  }

  .mobileMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: #7ABDE3;
    padding: 10px 0;
    z-index: +1111111111;
    margin: 15px 0 0 0;
  }

  .mobileMenu span:last-child {
    border-bottom: none;
  }

  .mobileMenu span:first-child {
    padding-top: 50px;
  }

  .mobileMenu span {
    font-size: 18px;
    padding: 20px;
    cursor: pointer;
    color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
  }

  .mobileMenu span:hover {
    background-color: #6AA0C9;
  }
}

@media (max-width: 442px) {
  .Logo {
    width: 80px;
  }
  .user {
    width: 30px;
    height: 30px;
  }
  .cartContainer {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 380px) {
  .navbar {
    height: 55px;
  }
  .Logo {
    width: 55px;
  }
  /* .cartAmount {
    left: 30px;
    top: 10px;
  } */
}