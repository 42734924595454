.BookcategoriesContainer{
    width: 100%;
    height: 100%;
}
.bookcontainer{
    width: 100%;
    max-width: 1200px;
    height: auto;
    margin: 0 auto;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    padding-right: .75rem;
    padding-left: .75rem;
}
.santacontainer{
    /* padding: 50px;
    height: 200vh; */

    /* height: auto; */
    /* margin-top: -0.5rem;
    width: 100%;
     */
    margin-top: -0.5rem;
    padding: 30px 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.book{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.bookcolumnmain{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
}
.iconaddtocart{
    transition: 0.5s;
}
.iconaddtocart:hover{
    transform: scale(1.1);
}
.active{
    box-shadow: 0 0 0 3px #c8f3fa, inset 0 0 0 2px #fff;
}
.viewallbooks{
    font-family: 'Textfontfamily';
    font-size: 18px;
    font-weight: 400;
    width: 135px;
    /* background-color: #452565; */
    border-radius: 24px;
    color: #FFFFFF;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 3% auto;
    padding: 0.7rem 2rem;
    cursor: pointer;
    transition: 0.5s;
  }
  .viewallbooks:hover{
    transform: scale(1.1);
  }
  .themainimage{
    position: relative;
    user-select: none;
  }
  .imagedots{
    /* position: absolute;
    bottom: -8%;
    left: 50%; */
    width: 15%;
    /* transform: translate(-50%, -50%); */
    margin: 0.5rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }
  .thedot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid gray;
  }
  .thedotactive{
    background-color: #452565;
  }
  .thumbnail{
    cursor: pointer;
    user-select: none;
  }
.bookbottom{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #FFFFFF;
    /* margin-top: 30px; */
    margin: 10px auto;
    padding: 0.5rem;
    cursor: pointer;
    font-family: 'Textfontfamily';
    z-index: 998;
    transition: 0.5s;
}
.bookbottom:hover{
    transform: scale(1.1);
}
.bookcolumn{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 25px 15px 0;
}
.row1{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 1% auto;
}
.text{
    margin-left: 0.8rem;
    font-size: 1rem;
    font-family: 'Textfontfamily';
    color: rgba(34, 31, 31, 0.719);
}
.price{
    font-size: 16px;
    color: #DE3226;
    font-weight: bold;
}
.bookcolumn img{
    height: 88px;
    display: block;
    object-fit: cover;
    border-radius: 8px;
    flex: 1 auto;
}
.bookmain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.bookmain img{
    width: 450px;
    height: 400px;
    display: block;
    object-fit: cover;
    border-radius: 8px;
}
.carousalBtns {
    width: 30px;
    height: 30px;
    color: gray;
    cursor: pointer;
}
.bookdetails{
    width: 47%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 0 4%;
}
.bookdetailstop{
    display: flex;
    flex-direction: column;
    padding: 0 15px;
}
.paragraph{
    font-family: 'Textfontfamily';
    margin-top: 2%;
    text-align: left;
    font-size: 1rem;
    color: rgba(34, 31, 31, 0.719);
    /* text-align-last: center; */
}
.heading{
    font-family: 'CustomFont';
    font-size: 3vw;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
}

.astronautcharacter{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}
.astronautcharacter img{
    width: 100%;
    max-width: 400px;
    height: auto;
}
.footer{
    margin-top: 0 !important;
}
.reviews {
    min-width: 100%;
    min-height: 110vh;
    height: auto;
    /* background-size: cover; */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    margin-top: -50px;
}
  .doorbean{
    position: absolute;
    left: 0;
    top: 10%;
    transform: translate(0%, -50%);
  }
  .doorbean img{
    width: 70%;
    display: block;
    object-fit: cover;
  }

  .prev_btn {
    position: absolute;
    top: 185px;
    left: 5px;
    border-radius: 50%;
    background: gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    z-index: +1;
  }

  .next_btn {
    position: absolute;
    top: 185px;
    right: 5px;
    border-radius: 50%;
    background: gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    z-index: +1;
  }

  .newsletter {
    min-width: 100%;
    min-height: 125vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.ourbestsellers {
    /* min-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative !important;
    margin-bottom: 22%;
    z-index: 9999; */
    /* align-items: center; */
    /* display: flex; */
    /* flex-direction: column; */
    /* height: 100%; */
    /* justify-content: center; */
    height: 100%;
    margin-bottom: 35%;
    min-width: 100%;
    position: relative !important;
    z-index: 998;
  }
.Astronautsfyling{
    position: absolute;
    transform: translate(0%, -50%);
  }
  .Astronautsfyling img{
    width: 80%;
    display: block;
    object-fit: cover;
  }
  .hills {
    /* min-width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    bottom: 0%;
    margin-bottom: -190px; */

    min-height: 100vh;
    min-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* background-size: cover; */
    position: absolute;
    bottom: 0;
    margin-bottom: -200px;
    
  }

  .bestsellericonone{
    position: absolute;
    right: 0;
    top: 0;
  }

  .bestsellericontwo{
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .bestsellericonthree{
    position: absolute;
    left: 0;
    top: 0;
  }

  .Footer{
    background-image: url('../../Assets/Backgrounds/Footer/footerbackground.webp');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  .Footerred{
    background-image: url('../../Assets/Backgrounds/Footer/redfooter.webp');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  .footertopbanner{
    position: relative;
    height: auto;
  }

/* Large phones (portrait and landscape) */
@media (max-width: 1070px) {
    .bookcontainer{
        /* border: 1px solid black; */
        width: 100%;
        height: auto;
        margin: 4% auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .bookmain img{
        width: 100%;
        /* padding: 0 15px; */
     }
    .book{
        width: 100%;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        margin: 0 auto 1rem;
    }
    .bookbottom{
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        color: #FFFFFF;
        /* background-color: #452565; */
        margin: 20px auto 0;
        padding: 0.5rem;
        cursor: pointer;
        font-family: 'Textfontfamily';
        z-index: +111111111;
    }
    .bookdetails{
        width: 80%;
        margin: 0 auto;
    }
    .column{
        margin-top: 20px;
    }
    .reviews{
        min-height: 90vh;
        height: auto;
    }
    .doorbean{
        width: 50%;
        top: 20%;
    }
    .doorbean img{
        width: 40%;
        top: 20%;
    }
    .newsletter{
        /* min-height: 55%; */
        min-height: 80%;
        background-size: cover;
    }
    .Astronautsfyling{
        width: 50%;
        right: -25% !important;
    }
    .Astronautsfyling img{
        width: 50%;
    }
    .ourbestsellers{
        height: auto;
    }
    .bookcolumnmain{
        gap: 25px;
        flex-direction: column-reverse;
    }
    .bookcolumn{
        width: 100%;
        flex-direction: row;
        align-items: center;
        /* flex-wrap: wrap; */
    }
    .astronautcharacter img{
        width: 70%;
    }
    .imagedots{
        width: 25%;
    }
}
/* @media (min-width: 1024px) {
.bookdetails{
    width: 40%;
}
} */
@media (max-width:480px){
    .thumbnail{
        height: 60px !important;
    }
    .astronautcharacter img{
        width: 70%;
    }
    .bookdetails{
        width: 100%;
    }
    .heading{
        font-size: 5vw;
    }
    .reviews {
        min-width: 100%;
        min-height: 60vh;
    }
    .newsletter{
        /* min-height: 55%; */
        min-height: 60%;
        background-size: cover;
    }
    .hills{
        min-height: 50vh;
    }
    .ourbestsellers{
        margin-bottom: 50%;
    }
    
}

@media (max-width:780px){
    .bestsellericonone{
        display: none;
    }
    .bestsellericontwo{
        display: none;
    }
    .bestsellericonthree{
        display: none;
    }
}

/* Tablets (portrait and landscape) */
/* @media (min-width: 769px) and (max-width: 1024px) {
  
} */
