  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .heading {
    font-family: 'CustomFont';
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color: #452565;
  }

  .loading-book {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .loading-book p{
    font-family: 'Textfontfamily';
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color: #452565;
  }
  .modal-content {
    width: 90%;
    height: 90%;
    max-width: 1340px;
    min-height: 500px;
    max-height: 720px;
    position: relative;
    /* padding: 20px; */
    border-radius: 8px;
    background: url('../../../../../Assets/Backgrounds/Home/ourbestseller.webp') center/cover no-repeat;
    background-color: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(25px) saturate(180%);
    -webkit-backdrop-filter: blur(25px) saturate(180%);
    box-shadow: 0px 10px 30px rgba(255, 255, 255, 0.25),
      inset 0px 0px 10px rgba(255, 255, 255, 0.1);

    border: 1px solid #fff;
  }


  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #e74c3c;
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 50%;
  }

  .close-btn:hover {
    background: #c0392b;
  }

  .pdf-book-wrapper {
    text-align: center;
    margin: 0 auto;
    max-width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }

  .flipbook-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    /* margin-top: 40px; */

  }

  .flipbook {
    width: 90%;
    height: 300px;
    margin: auto;
  }

  .flipbook-page {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 4px;
    padding: 8px;
    border: 0.3px solid gray;
    margin-left: 2px;
    position: absolute;
    backface-visibility: hidden;
    z-index: 1;
  }

  .flipbook-cover-page {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 4px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    backface-visibility: hidden;
    z-index: 1;
  }

  .flipbook-cover-page:hover {
    box-shadow: none
  }

  .flipbook-page.active {
    z-index: 10;
  }

  .flipbook-page.flipping {
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
    transform: rotateY(0deg);
  }

  .flipbook-page img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .flipbook .page:nth-child(odd) {
    transform: none !important;
  }

  .books-nav-btn {
    width: 45px;
    height: 42px;
    font-size: 18px;
    background-color: #fff;
    color: #8c5abe;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }

  .books-nav-btn:hover {
    background-color: #7a4e99;
    color: #fff;
  }

  .books-nav-btn:disabled {
    background-color: #ccc;
    color: gray;
    cursor: not-allowed;
  }

  .left {
    margin-right: 10px;
  }

  .right {
    margin-left: 10px;
  }

  .progress-bar-wrapper {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    z-index: -1;
  }

  .progress-bar {
    background-color: #ddd;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }

  .progress {
    background-color: #8c5abe;
    height: 100%;
    transition: width 0.3s ease;
  }

  .mobile-buttons{
    display: none;
  }
  @media (max-width:425px){
  .heading {
    font-family: 'CustomFont';
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color: #452565;
  }

  .pdf-book-wrapper{
    height: auto;
  }

  .flipbook-container{
    height: auto;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    min-height: 500px;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .books-nav-btn{
    display: none;
  }

  .mobile-books-nav-btn {
    width: 45px;
    height: 42px;
    font-size: 18px;
    background-color: #fff;
    color: #8c5abe;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }

  .mobile-books-nav-btn:hover {
    background-color: #7a4e99;
    color: #fff;
  }

  .mobile-books-nav-btn:disabled {
    background-color: #ccc;
    color: gray;
    cursor: not-allowed;
  }

  .mobile-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}