.OTPConfirmationModelmain{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.confirmationmodelsignin{
    position: relative;
    background-color: #fff;
    width: 40%;
    height: auto;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.otpmsg{
    font-size: 14px;
    font-weight: bold;
    color: gray;
}
.otpheading{
    margin: 0.5rem 0;
    color: gray;
}
.otpform input{
    margin: 0.7rem auto 0;
    padding: 0.5rem 1rem;
    width: 90%;
    border-radius: 25px;
    outline: none;
    border: 1.5px solid gray;
}
 .resetinput{

 }
.confirmbtn{
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #1A454C;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin: 0 auto;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: background-color 0.3s ease;
}
.container{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.sentemailheading{
    color: gray;
    /* padding-bottom: 15px; */
}
.sentemail{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding: 10px;
}
.sentemail input{
    width: 100%;
    padding: 0.5rem;
    margin: 10px auto 0;
    border-radius: 25px;
    outline: none;
    border: 1px solid gray;
    
}
.submit{
    padding: 0.5rem 1rem;
    background-color: rgb(86, 86, 236);
    border-radius: 25px;
    overflow: hidden;
    margin-top: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.signinform{
    display: flex;
    flex-direction: column;
}
.resetbtn{
    width: 30%;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    background-color: rgb(86, 86, 236);
    border-radius: 25px;
    overflow: hidden;
    margin-top: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;;
}
.resendbtn{
    font-size: 12px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 1rem;
}

.resendbtn:hover{
    color: rgb(86, 86, 236);
}
.closemodel{
    position: absolute;
    top: 1.5%;
    right: 2.5%;
}

@media (max-width:600px){
    .confirmationmodelsignin{
        position: relative;
        background-color: #fff;
        width: 80%;
        height: auto;
        padding: 1rem;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

@media (max-width: 992px) and (min-width: 601px){
    .confirmationmodelsignin{
        position: relative;
        background-color: #fff;
        width: 60%;
        height: auto;
        padding: 1rem;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}