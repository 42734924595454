.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4eafc; /* Soft background complementing #8c5abe */
    padding: 20px;
  }
  
  .card {
    background: #ffffff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 450px;
    width: 100%;
    border: 3px solid #8c5abe;
  }
  
  .title {
    font-size: 32px;
    color: #8c5abe;
    margin-bottom: 20px;
    font-family: "Comic Sans MS", cursive, sans-serif;
  }
  
  .message {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
    font-family: "Arial", sans-serif;
  }
  
  .orderSummary {
    text-align: left;
    background-color: #f0e1ff; /* Light complement of #8c5abe */
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 2px dashed #8c5abe;
  }
  
  .orderSummary h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #8c5abe;
    font-family: "Comic Sans MS", cursive, sans-serif;
  }
  
  .orderSummary ul {
    list-style: none;
    padding: 0;
  }
  
  .orderSummary li {
    font-size: 14px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    color: #333;
    font-family: "Arial", sans-serif;
  }
  
  .button {
    background-color: #8c5abe;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    font-family: "Comic Sans MS", cursive, sans-serif;
  }
  
  .button:hover {
    background-color: #6e479d;
  }
  
  .CreateYourOwnBook{
    width: fit-content;
    cursor: pointer;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 50px;
    transition: 0.5s;
}
.CreateYourOwnBook:hover{
  transform: scale(1.1);
}
.buttondiv{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* border: 1px solid black; */
}

.ebooks{
  width: fit-content;
  font-weight: bold;
  color: #6e479d;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
  overflow: hidden;
  /* border-radius: 50px; */
  transition: 0.5s;
}