.profileTopRight {
    /* display: flex;
    flex-direction: column;
    gap: 20px;
    border: 2px solid #B6B6B6;
    border-radius: 20px;
    padding: 30px;
    width: 100%;
    height: 450px */

    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 2px solid #B6B6B6;
    border-radius: 20px;
    padding: 30px;
    width: 75%;
    height: 490px;
    position: relative;
    /* height: 450px;
    /* height: 50vh; */
  }
.rightTitle{
    color: #2DC7FB;
    font-family: 'CustomFont';
    font-size: 30px;
    font-weight: 400;
  }
  .profileRightContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    /* width: 500px; */
    padding: 30px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  .itemTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ribbon {
    background: #2DC7FB; /* Change to your preferred color */
    color: white;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


  .rightSubTitle{
    color: #2DC7FB;
    font-size: 22px;
  }
  .rightPara {
    /* color: #FFFFFF; */
    width: 458px;
    text-align: center;
    padding: 15px 24px;
    text-align: center;
    width: 100%;
  }
  .leftBtnsContainer {
    /* width: 280px; */
  }
  .leftBtns {
    outline: none;
    border: none;
    background: #2DC7FB;
    width: 100%;
    border: 2px solid #ffffff;
    border-radius: 15px;
    padding: 12px 0;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}
.orderItem {
    display: flex;
    align-items: center;
    gap: 15px;
    background: white;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.itemDetailContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}
.itemTop {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}
.itemBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.date{
    color: #B6B6B6;
    font-size: 12px;

}
.priceContainer {
    font-size: 14px;
    color: #B6B6B6;

}
.price{
    color: #DE3226;
}
.itemImg{
    width: 80px;
    height: 80px;
    border-radius: 10px;
}
.itemTitle{
    font-size: 22px;
    font-weight: 400;
}
.starsContainer {
    display: flex;
    align-items: center;
    gap: 2px;
}
.quantity {
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: flex-end;
}
 
.quantity_amount {
  border: 1px solid #cccccc;
  padding: 2px 14px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}
 
.price_date_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.quantity_btn {
  border: 1px solid #cccccc;
  padding: 2px 6px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
 
}


.variations {
  font-size: 11px;
  color: #454545;
}

.variations h4 {
  font-weight: bold;
}

.variations .columns {
  display: flex;
  justify-content: space-between;
}

.variations ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 48%; /* Adjust width to fit two columns */
}

.variations li {
  margin: 5px 0;
}





.logoutpopup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.579);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1330;
}
.logoutpopupbox{
  max-width: 450px;
  /* height: 100px; */
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}
.closeicon{
  font-size: 1.4rem;
  font-weight: bold;
  color: gray;
  cursor: pointer;
  transition: 0.5s;
  background-color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  /* padding: 5px; */
  position: absolute;
  top: -10%;
  right: -4%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 2px 0 rgba(20, 9, 9, 0.579);
}
.closeicon span{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.closeicon:hover{
  color: black
}
.btns{
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem auto;
  font-size: 0.9rem;
}
.text{
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
.cancel{
  width: 100%;
  padding: 6px 15px;
  /* background-color: gray; */
  border: 1px solid gray;
  border-radius: 10px;
  cursor: pointer;
  color: gray;
  margin: 0 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel:hover{
  background-color: rgb(73, 72, 72);
  color: #fff;
}
.logout{
  width: 100%;
  padding: 7px 15px;
  background-color: rgb(93, 93, 184);
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  margin: 0 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout:hover{
  background-color: rgb(54, 54, 119);
}


.textparagraph{
  color: gray;
  font-size: 1rem;
  margin: 0.5rem auto;
}

.promocodetitle{
  font-size: 12px;
  font-weight: bold;
  color: gray;
}



@media (max-width:992px){
  .profileTopRight{
    width: 100%;
  }
  .profileRightContent{
    padding: 15px !important;
  }
}


@media  (max-width: 768px){
  
  .orderItem{
    flex-direction: column;
  }
  .itemTitle{
    font-size: 12px;
    text-wrap: wrap;
    word-break: break-all;
  }
  .profileRightContent{
    padding: 30px;
  }
  .profileTopRight{
    padding: 15px;
    width: 90%;
    margin: 0 auto;
  }
}

@media  (max-width: 480px){
.itemBottom{
  flex-direction: column;
  justify-content: space-around;
  align-items: baseline;
}
}