.HomeContainer{
    width: 100vw;
    height: 100%;
}
.mainpage{
    width: 100vw;
    height: 100%;
    padding: 0 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* border: 1px solid black; */
}

.mainpageLeft{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.heading{
    font-family: 'CustomFont';
    font-size: 5vw;
    width: 80vw;
    text-align: center;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    color:#79BDE4;
}
.CreateYourOwnBook{
    cursor: pointer;
}
.mainpageRight{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.mainpageRight img{
    max-width: 100%;
    height: auto;
    margin-top: auto;
}

  .OurBooks {
    min-width: 100vw;
    height: 100vh;
    background-image: url('../../Assets/Backgrounds/UserProfile/userProfileBgTop.webp');

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex; 
    justify-content: center;
    margin-bottom: 800px;
}
.Profilefooterbanner{
  position: relative;
}
  
.Aboutus {
  min-width: 100%;
  min-height: 100%;
  height: 1000px;
  background-image: url('../../Assets/Backgrounds/UserProfile/userProfileBgBottom.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
    bottom: -190px;

}

.reviews{
  min-width: 100%;
  min-height: 80vh;
}
.HomeContactus {
  background-image: url('../../Assets/Backgrounds/UserProfile/userProfileBgBottom.webp');
  min-width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.Footer{
  position: absolute;
  width: 100%;
  bottom: -80px;
  left: 0;
  /* background-image: url('../../Assets/Backgrounds/Footer/footerbackground.png');
  width: 100vw; */
  /* height: 40%; */
  /* background-size: cover;
  background-repeat: no-repeat; */
  /* background-position: center; */
  /* position: absolute;
  bottom: -5%;
  left: 0%; */
}
  /* Styles for Webkit-based browsers */
::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Height of the scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #ccc; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners of the scrollbar thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #999; /* Color of the scrollbar thumb on hover */
  }