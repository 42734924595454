.main {
    width: 100%;
    height: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .heading {
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    margin: 2% auto;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    padding-top: 50px;
  }
  
  .reviewContainer {
    width: 80%;
    padding: 2%;
    margin: 1% auto;
    border: 1px solid #ccc;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .reviewName {
    font-size: 1.5em;
    font-weight: bold;
    color: #79BDE4;
    font-family: 'Textfontfamily';
  }
  .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .reviewText {
    font-size: 1em;
    color: #555;
    margin-top: 0.5em;
    font-family: 'Textfontfamily';
  }
/*   
  .reviewStars {
    font-size: 1.5em;
    margin-top: 0.5em;
    color: #ffd700;
  }
   */


.reviewStars {
    display: flex;
  }
  
  .star {
    font-size: 1.5em;
    color: lightgray; /* Default empty star color */
  }
  
  .star.full {
    color: #ffd700; /* Full star color */
  }
  
  .star.half {
    position: relative;
  }
  
  .star.half::before {
    content: '★';
    position: absolute;
    color: #ffd700; /* Half star color */
    width: 50%;
    overflow: hidden;
  }

  @media (max-width: 992px){
    .reviewText {
      font-size: 0.5em;
    }
    .reviewName {
      font-size: 1em;
    }
    .star{
      font-size: 1em;
    }
  }

  @media (max-width: 480px){
    .reviewContainer{
      width: 100%;
  }
  .reviewName{
    font-size: 3vw;
  }
  .reviewText{
    font-size: 0.6rem;
  }
  }