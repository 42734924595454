.content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    color: white;
    background-color: green;
    padding: 10px;
    border-radius: 4px;
}
.container{
    position: absolute;
    top: 15vh;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
}
.icon{
    width: 20px;
    height: 20px;
}