.contactusbtn{
    /* width: 200px;
    height: 50px; */
    /* width: 100%;
    height: 100%; */
    /* background-color: #B880F4; */
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* background: linear-gradient(to bottom, #BB82F9, #9265C2); */
  }
  .contactusbtninside{
    position: relative;
    width: 92%;
    height: 78%;
    /* background-color: #A774DD; */
    /* border: 1px solid #A774DD; */
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    align-items: center;
    color: #fff;
    /* box-shadow: inset 0 -3px 5px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.1);  */
  }
  .iconContainer{
    /* position: absolute;
    right: 5px; */
    /* width: 32px;
    height: 32px; */
    
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }