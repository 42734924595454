
.heading {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}
.main{
  position: relative;
  width: 100%;
}
.voucherListContainer {
  /* padding: 20px; */
  /* background-color: #f9f9f9; */
  /* min-height: 100vh; */
  width: 100%;
  height: auto;
  max-height: 70vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
}

.voucherTable {
  width: 100%;
  max-width: 1340px;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.voucherTable th,
.voucherTable td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem;
  color: #555;
}

.voucherTable th {
  /* background: linear-gradient(135deg, #4CAF50 0%, #66bb6a 100%); */
  background-color: rgb(93, 93, 184);
  color: white;
  font-weight: bold;
}

.voucherTable tr:nth-child(even) {
  background-color: #f7f7f7;
}

.voucherTable tr:hover {
  background-color: #f1f1f1;
}

.editButton,
.deleteButton {
  /* background-color: #4CAF50; */
  background-color: rgb(93, 93, 184);
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.deleteButton {
  background-color: #ff4d4d;
}

.editButton:hover{
  background-color: rgb(54, 54, 119);
}
.deleteButton:hover {
  background-color: #b60404;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 1340px;
}
.filter{
  width: 60%;
  display: flex;
  align-items: center;
}

.filterContainer input:focus {
  border-color: #5c6bc0;
  outline: none;
}

.searchInput {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 4px;
  margin: 0 0.5rem;
  border: 1px solid #ccc;
}

.selectFilter {
  width: 35%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.pagination {
  margin: 5px 0;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageItem {
  padding: 8px 12px;
  border: none;
  background-color: rgb(93, 93, 184);
  color: white;
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
}

.pageItem:hover {
  background-color: rgb(54, 54, 119);
}

.activePage {
  background-color: rgb(54, 54, 119);
}

.logoutpopup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.579);
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoutpopupbox{
  width: 450px;
  /* height: 100px; */
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px 20px;
}
.closeicon{
  font-size: 1.4rem;
  font-weight: bold;
  color: gray;
  cursor: pointer;
  transition: 0.5s;
  align-self: flex-end;
  background-color: lightgray;
  /* width: 30px;
  height: 30px; */
  border-radius: 50%;
  padding: 15px;
  position: relative;
}
.closeicon span{
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.closeicon:hover{
  color: rgb(73, 72, 72);
}
.btns{
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem auto;
}
.listmaindiv{
  position: relative;
}

.btns{
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem auto;
}
.text{
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
.cancel{
  width: 100%;
  padding: 8px 15px;
  /* background-color: gray; */
  border: 1px solid gray;
  border-radius: 10px;
  cursor: pointer;
  color: gray;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel:hover{
  background-color: rgb(73, 72, 72);
  color: #fff;
}
.logout{
  width: 100%;
  padding: 8px 15px;
  background-color: rgb(93, 93, 184);
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout:hover{
  background-color: rgb(54, 54, 119);
}


.textparagraph{
  color: gray;
  font-size: 0.9rem;
  margin: 0.5rem auto;
}
