.svg-container {
    /* Default size */
    width: 50px;
    /* height: 50px; */
  }
  
  @media (min-width: 600px) {
    .svg-container {
        width: 75px;
    }
  }
  
  @media (min-width: 900px) {
    .svg-container {
        width: 100px;

    }
  }
  
  @media (min-width: 1200px) {
    .svg-container {
        width: 100px;

    }
  }
  