.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    max-width: 1340px;
    width: 100%;
    /* margin: 10% auto; */
  }
  /* src/components/OurBooks.module.css */
  .bookContainer {
    width: 100%;
    max-width: 1340px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  
  .mainheading{
    font-family: 'CustomFont';
    font-size: 5vw;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    white-space: normal;
    word-wrap: break-word;
    padding-top: 50px;
    /* color: #FFFFFF; */
  }
  .carouselContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }
  .carousel{
    display: flex;
    /* width: 100%; */
    justify-content: center;
    align-items: center;
  }
  
  
  .prev_btn {
    border-radius: 50%;
    background: gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
  }
  .carousalBtns {
    width: 30px;
    height: 30px;
    color: gray;
    cursor: pointer;
}
.next_btn {
    border-radius: 50%;
    background: gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
  }